import Types from '@taktik/common/types';
import { useEffect, useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import BonusDialog from './BonusDialog';
import theme from '../../../theme';
import Typography from '@mui/material/Typography';
import PresentationDialog from './PresentationDialog';
import { connect } from 'react-redux';
import TaktikIcon from '@taktik/components/TaktikIcon';

const useStyle = makeStyles({
	bonuses: {
		height: 'inherit',
	},
	bookmark: {
		position: 'absolute',
		backgroundColor: 'rgb(254,198,61)',
		borderColor: 'rgb(254,198,61)',
		width: '100%',
		display: 'flex',
		alignItems: 'center',
		padding: '.3em',
		cursor: 'pointer',
		'&:hover': {
			backgroundColor: '#ffb700',
			borderColor: '#ffb700',
			paddingRight: '.6em',
		},
		transition: 'transform .5s, padding .5s, opacity 1s',
	},
	presentation: {
		cursor: 'pointer',
		position: 'absolute',
		background: 'rgb(254,198,61)',
		boxShadow: '0px 3px 6px 0px rgb(50 50 50 / 60%)',
		borderRadius: '100%',
		animation: 'pointer1 2s 0s ease-out 1',
		'&:hover': {
			animation: 'pointer1h 0.5s 0.4s ease-out infinite',
		},
	},
	thumbnail: {
		position: 'absolute',
		transition: 'opacity 1s, left 0.5s, right 0.5s',
		zIndex: 3,
	},
});

export const getBonusIcon = (type: Types.BonusTypes, iconHeight?: number) => {
	switch (type) {
		case 'video':
			return <TaktikIcon icon="videoPlay" size={iconHeight || 14} />;
		case 'link':
			return <TaktikIcon icon="earth" size={iconHeight || 14} />;
		case 'quiz':
			return <TaktikIcon icon="benefitIcon2" size={iconHeight || 14} />;
		case 'module':
			return <TaktikIcon icon="puzzle" size={iconHeight || 14} />;
		case 'presentation':
			return <TaktikIcon icon="teacher" size={iconHeight || 14} />;
		case 'risk':
			return <TaktikIcon icon="benefitIcon2" size={iconHeight || 14} />;
		default:
			return <TaktikIcon icon="earth" size={iconHeight || 14} />;
	}
};

interface BonusesProps {
	books: Types.BookDocument[];
	bookActive: Types.BookActiveProps;
	position: 'left' | 'right';
	page: Types.BookPageDocument;
	onOpen?: () => void;
	onClose?: () => void;
}

const Bonuses = (props: BonusesProps) => {
	const page = props.page;
	const classes = useStyle();
	const bookActive = props.bookActive;
	const bookmarkWidth = bookActive ? bookActive.pageDimensions.width * 0.12 : 10;
	const [show, setShow] = useState(false);
	const [hover, setHover] = useState<Types.Bonus>();
	const [active, setActive] = useState<Types.Bonus>();

	useEffect(() => {
		setShow(false);
		const timeout = setTimeout(() => {
			setShow(true);
		}, 10);
		return () => {
			clearTimeout(timeout);
		};
	}, [props.page]);

	return (
		<>
			{bookActive && (
				<div
					className={classes.bonuses}
					style={{
						width: bookmarkWidth,
						height: bookActive.pageDimensions.height,
						right: props.position === 'right' ? -bookmarkWidth : 'initial',
						left: props.position === 'left' ? -bookmarkWidth : 'initial',
						position: 'absolute',
					}}
				>
					{page.bonuses.map((bonus: Types.Bonus, key: number) => {
						if (bonus.position.isPoint) {
							return (
								<div
									key={`bonus-${bonus.bookId}-${key}`}
									id={`bonus-${key}`}
									style={{
										top: `${bookActive.pageDimensions.height * (bonus.position.top / 100)}px`,
										width: `${bookActive.pageDimensions.width * 0.04}px`,
										height: `${bookActive.pageDimensions.width * 0.04}px`,
										left:
											props.position === 'left'
												? `calc(${bookmarkWidth}px + ${
														bookActive.pageDimensions.width * (bonus.position.left / 100)
												  }px)`
												: undefined,
										right:
											props.position === 'right'
												? `calc(${bookmarkWidth}px + ${
														bookActive.pageDimensions.width * (bonus.position.left / 100)
												  }px)`
												: undefined,
										zIndex: 2,
										background: `#${bonus.color}`,
									}}
									className={classes.presentation}
									onClick={() => {
										if (props.onOpen) {
											props.onOpen();
										}
										setActive(bonus);
									}}
								></div>
							);
						}

						const iconHeight = bookActive.pageDimensions.height * 0.06;
						const iconSpacing = bookActive.pageDimensions.height * 0.01;

						return (
							<div
								key={`bonus-${bonus.bookId}-${key}`}
								id={`bonus-${key}`}
								style={{
									top: `${bookActive.pageDimensions.height * (bonus.position.top / 100)}px`,
									left: props.position === 'left' ? '0' : 'initial',
									right: props.position === 'right' ? '0' : 'initial',
									clipPath:
										props.position === 'left'
											? 'polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%, 15% 50%)'
											: 'polygon(0% 0%, 100% 0%, 85% 50%, 100% 100%, 0% 100%)',
									justifyContent: props.position === 'left' ? 'flex-end' : 'flex-start',
									transform: `translate(${
										show ? '0, 0' : props.position === 'left' ? '6rem, 0' : '-6rem, 0'
									})`,
									opacity: show ? 1 : 0,
									paddingTop: iconSpacing / 2,
									paddingBottom: iconSpacing / 2,
									background: `#${bonus.color}`,
								}}
								className={clsx(classes.bookmark)}
								onMouseEnter={() => {
									setHover(bonus);
								}}
								onMouseLeave={() => {
									setHover(undefined);
								}}
								onClick={() => {
									if (bonus.type === 'link') {
										window.open(bonus.url, '_blank', 'noreferrer');
									} else {
										if (props.onOpen) {
											props.onOpen();
										}
										setActive(bonus);
									}
								}}
							>
								{getBonusIcon(bonus.type, iconHeight)}
							</div>
						);
					})}
					<div
						className={classes.thumbnail}
						style={{
							background: theme.palette.common.white,
							width: hover?.image ? bookActive.pageDimensions.width * 0.6 : undefined,
							minWidth: bookActive.pageDimensions.width * 0.4,
							opacity: hover ? 1 : 0,
							top:
								hover && hover.position.top < 60
									? `${bookActive.pageDimensions.height * (hover.position.top / 100)}px`
									: undefined,
							bottom:
								hover && hover.position.top >= 60
									? `${bookActive.pageDimensions.height * ((93 - hover.position.top) / 100)}px`
									: undefined,
							left: props.position === 'left' ? (hover ? bookmarkWidth + 10 : bookmarkWidth) : undefined,
							right: props.position === 'right' ? (hover ? bookmarkWidth + 10 : bookmarkWidth) : undefined,
							boxShadow: hover ? '10px 10px 15px rgba(0,0,0,0.5)' : undefined,
							border: hover ? `10px solid ${theme.palette.common.white}` : undefined,
						}}
					>
						{hover && (
							<>
								<Typography gutterBottom={hover.image !== null} fontWeight={700} fontSize={22}>
									{hover.title}
								</Typography>
								{hover.image && (
									<img
										src={`${process.env.REACT_APP_FILES}${hover.image}`}
										width="100%"
										style={{ display: 'block' }}
									/>
								)}
							</>
						)}
					</div>
				</div>
			)}
			{active && (active.type === 'module' || active.type === 'video') && (
				<BonusDialog
					bonus={active}
					onClose={() => {
						setActive(undefined);
						if (props.onClose) {
							props.onClose();
						}
					}}
				/>
			)}
			{active && active.type === 'presentation' && (
				<PresentationDialog
					bonus={active}
					onClose={() => {
						setActive(undefined);
						if (props.onClose) {
							props.onClose();
						}
					}}
				/>
			)}
		</>
	);
};

export default connect((state: Types.RootState) => ({
	bookActive: state.bookActive,
	books: state.books,
}))(Bonuses);

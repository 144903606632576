import makeStyles from '@mui/styles/makeStyles';
import Types from '@taktik/common/types';
import { connect, useDispatch } from 'react-redux';
import AddIcon from '@mui/icons-material/Add';
import reducers from '@taktik/common/reducers';
import { useState } from 'react';
import BonusDialog from './BonusDialog';
import { getBonusIcon } from '../../BookDetail/components/Bonuses';

const useStyle = makeStyles({
	bonuses: {
		position: 'absolute',
		height: 'inherit',
	},
	bookmark: {
		position: 'absolute',
		backgroundColor: 'rgb(254,198,61)',
		borderColor: 'rgb(254,198,61)',
		width: '100%',
		display: 'flex',
		alignItems: 'center',
		padding: '.3em',
		cursor: 'pointer',
		'&:hover': {
			backgroundColor: '#ffb700',
			borderColor: '#ffb700',
			paddingRight: '.6em',
		},
		transition: 'transform .5s, padding .5s, opacity 1s',
	},
	presentation: {
		cursor: 'pointer',
		position: 'absolute',
		background: 'rgb(254,198,61)',
		boxShadow: '0px 3px 6px 0px rgb(50 50 50 / 60%)',
		borderRadius: '100%',
		animation: 'pointer1 2s 0s ease-out 1',
		'&:hover': {
			animation: 'pointer1h 0.5s 0.4s ease-out infinite',
		},
	},
	thumbnail: {
		position: 'absolute',
		transition: 'opacity 1s, left 0.5s, right 0.5s',
		zIndex: 3,
	},
});

interface BonusesEditorProps {
	user: Types.UserSession;
	bookActive: Types.BookActiveProps;
	page: Types.BookPageDocument;
	position: 'left' | 'right';
	onOpen?: () => void;
	onClose?: () => void;
}

const BonusesEditor = (props: BonusesEditorProps) => {
	const dispatch = useDispatch();
	const classes = useStyle();
	const bookActive = props.bookActive;
	const page = props.page;
	const [hover, setHover] = useState<Types.BonusDocument>();
	const [active, setActive] = useState<Types.BonusDocument>();

	const setControls = (active: boolean) => {
		if (bookActive) {
			dispatch(
				reducers.interaktiv.bookActive.actions.set({
					...bookActive,
					controls: active,
				})
			);
		}
	};

	if (bookActive) {
		const bookmarkWidth = bookActive.pageDimensions.width * 0.12;
		const iconSpacing = bookActive.pageDimensions.height * 0.01;
		const iconHeight = bookActive.pageDimensions.height * 0.06;

		return (
			<>
				<div
					className={classes.bonuses}
					style={{
						width: bookmarkWidth,
						height: bookActive.pageDimensions.height,
						right: props.position === 'right' ? -bookmarkWidth : 'initial',
						left: props.position === 'left' ? -bookmarkWidth : 'initial',
					}}
				>
					{page.bonuses.map((bonus, key) => {
						if (bonus.position.isPoint) {
							return (
								<div
									key={`bonus-${bonus.bookId}-${key}`}
									id={`bonus-${key}`}
									style={{
										top: `${bookActive.pageDimensions.height * (bonus.position.top / 100)}px`,
										width: `${bookActive.pageDimensions.width * 0.04}px`,
										height: `${bookActive.pageDimensions.width * 0.04}px`,
										left:
											props.position === 'left'
												? `calc(${bookmarkWidth}px + ${
														bookActive.pageDimensions.width * (bonus.position.left / 100)
												  }px)`
												: undefined,
										right:
											props.position === 'right'
												? `calc(${bookmarkWidth}px + ${
														bookActive.pageDimensions.width * (bonus.position.left / 100)
												  }px)`
												: undefined,
										zIndex: 2,
										background: `#${bonus.color}`,
									}}
									className={classes.presentation}
									onClick={() => {
										if (props.onOpen) {
											props.onOpen();
										}
										setControls(false);
										setActive(bonus);
									}}
								></div>
							);
						}

						const iconHeight = bookActive.pageDimensions.height * 0.06;
						const iconSpacing = bookActive.pageDimensions.height * 0.01;

						return (
							<div
								key={`bonus-${bonus.bookId}-${key}`}
								id={`bonus-${key}`}
								style={{
									top: `${bookActive.pageDimensions.height * (bonus.position.top / 100)}px`,
									left: props.position === 'left' ? '0' : 'initial',
									right: props.position === 'right' ? '0' : 'initial',
									clipPath:
										props.position === 'left'
											? 'polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%, 15% 50%)'
											: 'polygon(0% 0%, 100% 0%, 85% 50%, 100% 100%, 0% 100%)',
									justifyContent: props.position === 'left' ? 'flex-end' : 'flex-start',
									paddingTop: iconSpacing / 2,
									paddingBottom: iconSpacing / 2,
									background: `#${bonus.color}`,
								}}
								className={classes.bookmark}
								onMouseEnter={() => {
									setHover(bonus);
								}}
								onMouseLeave={() => {
									setHover(undefined);
								}}
								onClick={() => {
									setControls(false);
									setActive(bonus);
								}}
							>
								{getBonusIcon(bonus.type, iconHeight)}
							</div>
						);
					})}
					<div
						key={`overlay-${props.position}-bg`}
						style={{
							top: 0,
							left: props.position === 'left' ? '0' : 'initial',
							right: props.position === 'right' ? '0' : 'initial',
							clipPath:
								props.position === 'left'
									? 'polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%, 15% 50%)'
									: 'polygon(0% 0%, 100% 0%, 85% 50%, 100% 100%, 0% 100%)',
							justifyContent: props.position === 'left' ? 'flex-end' : 'flex-start',
							paddingTop: iconSpacing / 2,
							paddingBottom: iconSpacing / 2,
						}}
						className={classes.bookmark}
						onClick={() => {
							const newBonus: Omit<Types.Bonus, '_id'> = {
								title: 'Ezop',
								image: '',
								width: 0,
								height: 0,
								module: 'taktik_puzzle',
								params: 'list=1794',
								bookId: props.page.bookId,
								chapterId: 0,
								position: {
									isPoint: false,
									top: 15,
									left: 0,
								},
								url: '',
								type: 'module',
								theme: 1,
								color: 'fec63d',
								sort: 0,
								html: '',
								textHeight: 0,
								images: [],
							};
							dispatch(
								reducers.interaktiv.bookPages.actions.update(
									'add/bonus',
									{
										pageId: props.page._id,
										data: {
											value: newBonus,
										},
									},
									props.user.token
								)
							);
						}}
					>
						<AddIcon style={{ fontSize: iconHeight }} />
					</div>
				</div>
				{active && (
					<BonusDialog
						page={page}
						open
						onClose={() => {
							setControls(true);
							setActive(undefined);
						}}
						bonus={active}
					/>
				)}
			</>
		);
	}
	return null;
};

export default connect((state: Types.RootState) => ({
	user: state.user,
	bookActive: state.bookActive,
}))(BonusesEditor);

import AppBar from '@mui/material/AppBar';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import Grid from '@mui/material/Grid';
import Types from '@taktik/common/types';
import CloseIcon from '@mui/icons-material/Close';
import { forwardRef, ReactElement, Ref, useState, useEffect } from 'react';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import Typography from '@mui/material/Typography';
import { connect } from 'react-redux';

interface TestEditorDialogProps {
	onClose: () => void;
	books: Types.BookDocument[];
	bookActive: Types.BookActiveProps;
}

const Transition = forwardRef(function Transition(
	props: TransitionProps & {
		children: ReactElement;
	},
	ref: Ref<unknown>
) {
	return <Slide direction="up" ref={ref} {...props} />;
});

const TestEditorDialog = (props: TestEditorDialogProps) => {
	const bookActive = props.bookActive;
	const [book, setBook] = useState<Types.BookDocument>();

	useEffect(() => {
		setBook(props.books.find(b => b._id === bookActive?.bookId));
	}, [bookActive]);

	return (
		<Dialog open={true} onClose={() => props.onClose()} TransitionComponent={Transition} fullScreen>
			<AppBar sx={{ position: 'relative' }}>
				<Toolbar>
					<Grid container justifyContent="space-between" alignItems="center">
						<Grid item>
							<Typography variant="h6">Přehled testů</Typography>
						</Grid>
						<Grid item>
							<IconButton edge="start" color="inherit" onClick={props.onClose} className="close-dialog">
								<CloseIcon />
							</IconButton>
						</Grid>
					</Grid>
				</Toolbar>
			</AppBar>
			{book && (
				<iframe
					src={`https://interaktivita.etaktik.cz/moduly/v2-Testy-a-zkouseni/?rand=${performance.now()}&id_book=${
						book.id
					}&licence=full`}
					width="100%"
					height="100%"
				></iframe>
			)}
		</Dialog>
	);
};

export default connect((state: Types.RootState) => ({
	books: state.books,
	bookActive: state.bookActive,
}))(TestEditorDialog);

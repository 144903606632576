import makeStyles from '@mui/styles/makeStyles';
import theme from './theme';

const useStyle = makeStyles({
	root: {
		display: 'flex',
	},
	content: {
		flexGrow: 1,
		overflow: 'hidden',
	},
	container: {
		marginTop: '57px',
	},
	containerDark: {
		background: '#02253e',
		overflow: 'hidden',
		marginTop: '57px',
		minHeight: 'calc(100vh - 57px)',
		'& .MuiCircularProgress-root': {
			color: '#fff',
			'&.MuiCircularProgress-colorSecondary': {
				color: '#fec63d',
			},
		},
	},
	navButton: {
		fontSize: '14px !important',
		fontWeight: '900 !important',
		color: '#02253e !important',
	},
	navBooks: {
		paddingTop: '0 !important',
		'& .MuiListItem-root': {
			border: 'none',
		},
		'& .MuiListItemButton-root': {
			borderBottom: `1px solid #b2bdc4`,
		},
		'& .Mui-selected': {
			background: '#003f6d !important',
			'& .MuiListItemText-root .MuiTypography-root': {
				color: '#fff !important',
			},
		},
	},
	book: {
		transition: 'all 0.2s !important',
		cursor: 'pointer',
		'&:hover': {
			background: '#f37416 !important',
			color: '#fff !important',
			'& .MuiLink-root, & .MuiTypography-root': {
				color: '#fff !important',
			},
		},
	},
	button: {
		fontSize: 140,
	},
	buttonText: {
		fontSize: 80,
		position: 'absolute',
		color: theme.palette.grey[100],
	},
	loaderContainer: {
		width: '100%',
		height: '100vh',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		'&.inline': {
			height: 'auto',
		},
	},
	dialogActions: {
		paddingLeft: theme.spacing(3),
		paddingRight: theme.spacing(3),
		paddingBottom: theme.spacing(2),
		justifyContent: 'space-between',
	},
	dialogActionsSingle: {
		paddingTop: 0,
		justifyContent: 'flex-end',
	},
});

export default useStyle;

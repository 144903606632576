import Types from '@taktik/common/types';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Helpers from '@taktik/common/helpers';
import Preloader from '../../Preloader';
import { pageFlipDelay } from '..';
import Loader from '../../Loader';
import Box from '@mui/material/Box';

interface BookHorizontalProps {
	book: Types.BookDocument;
	bookActive: Types.BookActiveProps;
	bookPages: Types.BookPageDocument[];
	visiblePages: number[];
}

type HorizontalPage = {
	front: Types.BookPageDocument;
	back: Types.BookPageDocument;
};

const getHorizontalPages = (pages: Types.BookPageDocument[]) => {
	const pagesOrganized: HorizontalPage[] = [];
	let current = 0;
	pages.forEach(page => {
		if (!pagesOrganized[current]) {
			pagesOrganized[current] = {
				front: page,
				back: page,
			};
		} else {
			pagesOrganized[current].back = page;
			current = current + 1;
		}
	});
	return pagesOrganized;
};

const BookHorizontal = (props: BookHorizontalProps) => {
	const visiblePages = props.visiblePages;
	const bookActive = props.bookActive;
	const [currentPage, setCurrentPage] = useState(-1);
	const [horizontalPages, setHorizontalPages] = useState<HorizontalPage[]>([]);
	const [leafs, setLeafs] = useState<
		{
			id: number;
			flip: boolean;
			transition: boolean;
			leaf: {
				front?: Types.BookPageDocument;
				back?: Types.BookPageDocument;
			};
			flipping?: boolean;
		}[]
	>([
		{
			id: 0,
			flip: false,
			transition: true,
			leaf: {},
		},
		{
			id: 1,
			flip: false,
			transition: true,
			leaf: {},
		},
		{
			id: 2,
			flip: false,
			transition: true,
			leaf: {},
		},
		{
			id: 3,
			flip: false,
			transition: true,
			leaf: {},
		},
	]);
	const [pages, setPages] = useState<Types.BookPageDocument[]>([]);
	const [loadedImages, setLoadedImages] = useState<number[]>([-1]);

	const flipPage = (newPage: number, currentPage: number) => {
		if (newPage !== currentPage) {
			setLoadedImages(loadedImages =>
				[...loadedImages, newPage * 2, newPage * 2 + 1].filter(
					(value, index, array) => array.indexOf(value) === index
				)
			);
			setHorizontalPages(horizontalPages => {
				setLeafs(leafs => {
					leafs = leafs.map(leaf => ({ ...leaf, flipping: undefined }));
					const direction = newPage > currentPage ? 'next' : 'prev';
					if (direction === 'next') {
						const unflipped = leafs.find(l => !l.flip);
						if (unflipped) {
							unflipped.transition = true;
							unflipped.flip = true;
							unflipped.flipping = true;

							const nextPage = horizontalPages[newPage]?.back;
							if (nextPage) {
								unflipped.leaf.back = nextPage;
							}

							const end = newPage >= horizontalPages.length - 1;
							if (end) {
								const unflipped = leafs.filter(l => !l.flip);
								unflipped.forEach(u => {
									const leaf = leafs.find(l => l.id === u.id);
									if (leaf) {
										leaf.transition = true;
										leaf.flip = true;
									}
								});

								const backPage = horizontalPages[newPage]?.back;
								const frontPage = horizontalPages[currentPage + 1]?.front;
								leafs[3].flipping = true;
								leafs[3].transition = true;
								leafs[3].leaf.back = backPage;
								leafs[3].leaf.front = frontPage;
							} else {
								const unflippedIndex = leafs.findIndex(l => !l.flip);
								const followingPage = horizontalPages[newPage + 1]?.front;
								const nextIndex = unflippedIndex === -1 ? 3 : unflippedIndex;
								if (followingPage) {
									leafs[nextIndex].leaf.front = followingPage;
								}
							}

							const unflippedCount = leafs.filter(l => !l.flip).length;
							if (unflippedCount < 2) {
								setTimeout(() => {
									setLeafs(leafs => {
										leafs = Helpers.arrayMove(leafs, 0, 3);
										leafs[3].transition = false;
										leafs[3].flip = false;
										if (end) {
											leafs[3].leaf = {};
										}
										return leafs;
									});
								}, pageFlipDelay);
							}
						}
					} else {
						const flippedIndex = leafs.map(l => l.flip).lastIndexOf(true);
						const flipped = leafs[flippedIndex];

						if (flipped) {
							flipped.transition = true;
							flipped.flip = false;
							flipped.flipping = true;

							const prevPage = horizontalPages[newPage + 1]?.front;
							if (prevPage) {
								flipped.leaf.front = prevPage;
							}

							const end = newPage < 0;
							if (end) {
								const flipped = leafs.filter(l => l.flip);
								flipped.forEach(u => {
									const leaf = leafs.find(l => l.id === u.id);
									if (leaf) {
										leaf.transition = true;
										leaf.flip = false;
									}
								});

								const backPage = horizontalPages[currentPage]?.back;
								const frontPage = horizontalPages[0]?.front;
								leafs[0].flipping = true;
								leafs[0].transition = true;
								leafs[0].leaf.front = frontPage;
								leafs[0].leaf.back = backPage;
							} else {
								const priorPage = horizontalPages[newPage]?.back;
								const lastFlippedIndex = leafs.map(l => l.flip).lastIndexOf(true);
								const nextIndex = lastFlippedIndex === -1 ? 0 : lastFlippedIndex;
								const prevLeaf = leafs[nextIndex];
								if (priorPage && prevLeaf) {
									prevLeaf.flip = true;
									prevLeaf.transition = false;
									prevLeaf.leaf.back = priorPage;
								}
							}

							const flippedCount = leafs.filter(l => l.flip).length;
							if (flippedCount < 2) {
								setTimeout(() => {
									setLeafs(leafs => {
										leafs = Helpers.arrayMove(leafs, 3, 0);
										leafs[0].transition = false;
										leafs[0].flip = true;
										if (end) {
											leafs[0].leaf = {};
										}
										return leafs;
									});
								}, pageFlipDelay);
							}
						}
					}
					return leafs;
				});
				return horizontalPages;
			});
		}
	};

	useEffect(() => {
		const newHorizontalPages = getHorizontalPages(pages);
		setHorizontalPages(newHorizontalPages);
		setLeafs(leafs => {
			return leafs.map(leaf => {
				const page = newHorizontalPages[leaf.id];
				if (page) {
					leaf.leaf = { ...page };
				}
				return leaf;
			});
		});
		if (newHorizontalPages.length > 0) {
			const newPage = visiblePages[0] !== null ? visiblePages[0] / 2 : 0;
			setCurrentPage(currentPage => {
				flipPage(newPage, currentPage);
				return newPage;
			});
		}
	}, [pages]);

	useEffect(() => {
		if (horizontalPages.length > 0) {
			const newPage = visiblePages[0] !== null ? visiblePages[0] / 2 : 0;
			setCurrentPage(currentPage => {
				flipPage(newPage, currentPage);
				return newPage;
			});
		}
	}, [visiblePages]);

	useEffect(() => {
		const newPages = props.bookPages.filter(bp => bp.bookId === props.book._id);
		setPages(newPages);
	}, [props.bookPages]);

	return (
		<>
			{horizontalPages.length === 0 && (
				<Box
					sx={{
						position: 'absolute',
					}}
				>
					<Loader height="calc(100vh - 200px)" />
				</Box>
			)}
			{bookActive &&
				leafs.map((leaf, key) => {
					const frontPage = leaf.leaf?.front;
					const backPage = leaf.leaf?.back;
					const frontVisible =
						frontPage &&
						(loadedImages.indexOf(frontPage.page) > -1 ||
							frontPage.page === visiblePages[0] ||
							frontPage.page === visiblePages[1]);
					const backVisible =
						backPage &&
						(loadedImages.indexOf(backPage.page) > -1 ||
							backPage.page === visiblePages[0] ||
							backPage.page === visiblePages[1]);

					return (
						<div
							className="flip"
							key={`page-${leaf.id}`}
							style={{
								width: bookActive.pageDimensions.width,
								height: bookActive.pageDimensions.height,
								transition: leaf.transition ? undefined : 'none',
								transformOrigin: 'left',
								transform: leaf.flip ? 'rotateY(-180deg)' : undefined,
								zIndex: leaf.flipping ? 7 : leaf.flip ? leafs.length + key : leafs.length - key,
							}}
							id={`page-${leaf.id}`}
						>
							<div
								className="front"
								style={{
									background: frontPage ? '#fff' : undefined,
								}}
							>
								{frontPage && frontVisible && frontPage.image && (
									<img
										src={`${process.env.REACT_APP_FILES}${frontPage.image}`}
										style={{
											width: bookActive.pageDimensions.width,
											height: bookActive.pageDimensions.height,
											opacity: bookActive?.editor.mode !== 'preview' ? 0.5 : undefined,
											display: 'block',
										}}
										draggable={false}
									/>
								)}
							</div>
							<div
								className="back"
								style={{
									background: backPage ? '#fff' : undefined,
								}}
							>
								{backPage && backVisible && backPage.image && (
									<img
										src={`${process.env.REACT_APP_FILES}${backPage.image}`}
										style={{
											width: bookActive.pageDimensions.width,
											height: bookActive.pageDimensions.height,
											opacity: bookActive?.editor.mode !== 'preview' ? 0.5 : undefined,
											display: 'block',
										}}
										draggable={false}
									/>
								)}
							</div>
						</div>
					);
				})}
		</>
	);
};

export default connect((state: Types.RootState) => ({
	bookActive: state.bookActive,
	bookPages: state.bookPages,
}))(BookHorizontal);

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import { connect, useDispatch } from 'react-redux';
import Types from '@taktik/common/types';
import { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Helpers from '@taktik/common/helpers';
import CloseIcon from '@mui/icons-material/Close';
import reducers from '@taktik/common/reducers';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';

interface ContactUsDialogProps {
	user: Types.UserSession;
	onClose: () => void;
	books: Types.BookDocument[];
	bookActive: Types.BookActiveProps;
}

const ContactUsDialog = (props: ContactUsDialogProps) => {
	const dispatch = useDispatch();
	const bookActive = props.bookActive;
	const [loading, setLoading] = useState(false);
	const [sent, setSent] = useState(false);
	const [book, setBook] = useState<Types.BookDocument>();
	const [form, setForm] = useState({
		email: props.user.email,
		phone: '',
		message: '',
	});

	const sendFeedback = (feedback: Types.BookFeedback) => {
		setLoading(true);
		dispatch(reducers.interaktiv.bookFeedback.actions.add(feedback, props.user.token)).then(() => {
			setLoading(false);
			setSent(true);
		});
	};

	useEffect(() => {
		setBook(props.books.find(b => b._id === bookActive?.bookId));
	}, [bookActive]);

	return bookActive ? (
		<Dialog open={true} onClose={() => props.onClose()} maxWidth="md" fullWidth>
			<DialogTitle>
				<Grid container justifyContent="space-between" alignItems="center">
					<Grid item>
						{sent && 'Vaše zpráva byla úspěšně odeslána.'}
						{!sent && 'Kontaktujte nás'}
					</Grid>
					<Grid item>
						<IconButton edge="start" color="inherit" onClick={props.onClose} className="close-dialog">
							<CloseIcon />
						</IconButton>
					</Grid>
				</Grid>
			</DialogTitle>
			<DialogContent>
				{!sent && (
					<Grid container spacing={3}>
						<Grid item md={4}>
							<TextField
								label="E-mail"
								value={form.email}
								onChange={event =>
									setForm({
										...form,
										email: event.target.value,
									})
								}
								fullWidth
							/>
						</Grid>
						<Grid item md={4}>
							<TextField
								label="Telefon"
								value={form.phone}
								onChange={event =>
									setForm({
										...form,
										phone: event.target.value,
									})
								}
								fullWidth
							/>
						</Grid>
						<Grid item md={4}>
							<TextField label="Kniha" value={book?.title} fullWidth disabled />
						</Grid>
						<Grid item md={12}>
							<TextField
								label="Váš vzkaz"
								onChange={event =>
									setForm({
										...form,
										message: event.target.value,
									})
								}
								fullWidth
								rows={4}
								multiline
							/>
						</Grid>
						<Grid item md={8}></Grid>
						<Grid item md={4}>
							<Button
								variant="contained"
								color="secondary"
								disabled={!Helpers.validateEmail(form.email) || form.message.length === 0 || loading}
								startIcon={loading ? <CircularProgress size={16} color="secondary" /> : null}
								fullWidth
								onClick={() =>
									sendFeedback({
										bookId: bookActive.bookId,
										userId: props.user._id,
										message: form.message,
										email: form.email,
										phone: form.phone,
										state: 'new',
									})
								}
							>
								Odeslat
							</Button>
						</Grid>
					</Grid>
				)}
				{sent && (
					<Typography variant="h6">Děkujeme za zaslání dotazu, ozveme se Vám co nejdříve.</Typography>
				)}
			</DialogContent>
		</Dialog>
	) : null;
};

export default connect((state: Types.RootState) => ({
	user: state.user,
	books: state.books,
	bookActive: state.bookActive,
}))(ContactUsDialog);

import * as React from 'react';
import { ImageSizes } from '@taktik/common/types';

interface ImageProps {
	path: string;
	alt?: string;
	size?: ImageSizes;
	style?: React.CSSProperties;
	isPublic?: boolean;
}

const Image = (props: ImageProps) => {
	const filesPath = process.env.REACT_APP_FILES;

	const getImageSize = (path: string, imageSize?: ImageSizes) => {
		switch (imageSize) {
			case 'medium':
				return path.replace(/large/g, 'medium');
			case 'thumbnail':
				return path.replace(/large/g, 'thumbnail');
			default:
				return path;
		}
	};

	const getImageStyle = (imageSize?: ImageSizes) => {
		let style: React.CSSProperties = {
			maxWidth: '100%',
			maxHeight: 128,
		};
		switch (imageSize) {
			case 'medium':
				style = {
					maxWidth: '100%',
					maxHeight: 64,
				};
				break;
			case 'thumbnail':
				style = {
					maxWidth: '100%',
					maxHeight: 32,
				};
				break;
		}
		return style;
	};

	return (
		<img
			src={props.isPublic ? props.path : filesPath + getImageSize(props.path, props.size)}
			style={props.style ? props.style : getImageStyle(props.size)}
			alt={props.alt}
		/>
	);
};

export default Image;

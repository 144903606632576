import { CSSProperties } from 'react';
import './font.css';

export const Icons = {
	informatika: 'informatika',
	messages: 'messages',
	smajlik: 'smajlik',
	greatBritain: 'great-britain',
	arrowClockwise: 'arrow-clockwise',
	arrowDown: 'arrow-down',
	arrowDownNarrow: 'arrow-down-narrow',
	arrowDownRound: 'arrow-down-round',
	arrowDownThin: 'arrow-down-thin',
	arrowDownWide: 'arrow-down-wide',
	arrowLeft: 'arrow-left',
	arrowLeftNarrow: 'arrow-left-narrow',
	arrowLeftRound: 'arrow-left-round',
	arrowLeftThin: 'arrow-left-thin',
	arrowLeftWide: 'arrow-left-wide',
	arrowRight: 'arrow-right',
	arrowRightNarrow: 'arrow-right-narrow',
	arrowRightRound: 'arrow-right-round',
	arrowRightThin: 'arrow-right-thin',
	arrowRightWide: 'arrow-right-wide',
	arrowUp: 'arrow-up',
	arrowUpNarrow: 'arrow-up-narrow',
	arrowUpRound: 'arrow-up-round',
	arrowUpThin: 'arrow-up-thin',
	arrowUpWide: 'arrow-up-wide',
	atom: 'atom',
	book: 'book',
	bookLight: 'book-light',
	brain: 'brain',
	butterfly: 'butterfly',
	calculator: 'calculator',
	check: 'check',
	checkRound: 'check-round',
	close: 'close',
	columns: 'columns',
	cow: 'cow',
	crown: 'crown',
	czechRepublic: 'czech-republic',
	earth: 'earth',
	eraser: 'eraser',
	exclamationMark: 'exclamation-mark',
	fullScreen: 'full-screen',
	leaf: 'leaf',
	letter: 'letter',
	letters: 'letters',
	lock: 'lock',
	mortarBoard: 'mortar-board',
	note: 'note',
	pageEdit: 'page-edit',
	pages: 'pages',
	pen: 'pen',
	pencil: 'pencil',
	plus: 'plus',
	puzzle: 'puzzle',
	questionMark: 'question-mark',
	reading: 'reading',
	retort: 'retort',
	star: 'star',
	taktikLogo: 'taktik-logo',
	taktikLogoText: 'taktik-logo-text',
	teacher: 'teacher',
	user: 'user',
	videoPlay: 'video-play',
	zoom: 'zoom',
	zoomIn: 'zoom-in',
	zoomOut: 'zoom-out',
	checkbox: 'checkbox',
	cogs: 'cogs',
	comment: 'comment',
	logIn: 'log-in',
	home: 'home',
	bars: 'bars',
	file: 'file-o',
	folder: 'folder-o',
	sort: 'sort',
	bookmark: 'bookmark-o',
	crosshairs: 'crosshairs',
	playCircle: 'play-circle',
	arrows: 'arrows',
	arrowsH: 'arrows-h',
	arrowsV: 'arrows-v',
	codeFork: 'code-fork',
	androidExpand: 'android-expand',
	benefitIcon1: 'benefit-icon1',
	benefitIcon2: 'benefit-icon2',
	benefitIcon3: 'benefit-icon3',
	keyboard: 'keyboard',
	logOut: 'log-out',
	flag: 'flag',
	flag1: 'flag-1',
	flag0: 'flag-o',
	flagCheckered: 'flag-checkered',
	volumeUp: 'volume-up',
	volumeOff: 'volume-off',
	volumeDown: 'volume-down',
	play: 'play',
	music: 'music',
	pause: 'pause',
	cart: 'cart',
	vydavatelstvoTaktikLogo: 'vydavatelstvo-taktik-logo',
	technikaIcon: 'technika-icon',
	mergeFill: 'merge-fill',
	mergeStroke: 'merge-stroke',
};

export interface IconProps {
	icon: keyof typeof Icons;
	size: number;
	color?: string;
	style?: CSSProperties;
}

const Icon = (props: IconProps) => {
	const iconClass = Icons[props.icon];
	return (
		<div
			className={`icon-${iconClass}`}
			style={{
				width: props.size,
				height: props.size,
				fontSize: props.size,
				color: props.color,
				display: 'inline-flex',
				...props.style,
			}}
		></div>
	);
};

export default Icon;

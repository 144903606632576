import { RootState, BookActiveProps, BookPageDocument, AreaDocument } from '@taktik/common/types';
import { useRef, useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import _ from 'lodash';
import { Types } from '@taktik/common';
import reducers from '@taktik/common/reducers';

const canvasWidth = 3000;
const canvasHeight = 4240;
const drawingSpeed = {
	normal: 10,
	fast: 0,
};

type Step = [string, string, string, string];

interface CanvasProps {
	page: BookPageDocument;
	bookActive: BookActiveProps;
}

const Canvas = (props: CanvasProps) => {
	const dispatch = useDispatch();
	const page = props.page;
	const bookActive = props.bookActive;
	const bookActiveCanvas = bookActive?.canvas;
	const [loading, setLoading] = useState(true);
	const [canvases, setCanvases] = useState(
		props.page.areas
			.filter(a => a.type === 'canvas')
			.map(area => ({
				isDrawing: false,
				isDrawn: false,
				area,
			}))
	);
	const activeArea = useRef<Types.AreaDocument>();
	const [drawingInterval, setDrawingInterval] = useState<NodeJS.Timer>();
	const intervalMs = useRef(drawingSpeed.normal);

	// canvas refs
	const overlays = useRef<HTMLImageElement[]>([]);
	const canvasRef = useRef<HTMLCanvasElement>(null);
	const canvasRef2 = useRef<HTMLCanvasElement>(null);
	const context = useRef<CanvasRenderingContext2D | null>(null);
	const context2 = useRef<CanvasRenderingContext2D | null>(null);

	// animation refs
	const currentStep = useRef(0);
	const offset = useRef({
		x: 0,
		y: 0,
	});
	const schields = useRef<[boolean, number, number, number][]>([]);
	const steps = useRef<Step[]>([]);
	const shieldCount = useRef(0);
	const schieldAct = useRef(0);
	const schieldSub = useRef(0);
	const brushSize = useRef(10);
	const brushSoft = useRef(10);

	const loadLayers = async () => {
		const layers = Object.keys(_.groupBy(canvases, canvas => canvas.area.layer)).map(a =>
			parseInt(a)
		);
		const promises = layers.map(layer => {
			return new Promise((resolve, reject) => {
				const image = new Image();
				image.onload = () => {
					resolve(null);
					overlays.current = [...overlays.current, image];
				};
				image.crossOrigin = 'Anonymous';
				image.src = `${process.env.REACT_APP_FILES}${props.page.overlays[layer]}`;
			});
		});
		return await Promise.all(promises);
	};

	const drawStep = () => {
		if (context.current && context2.current) {
			const step = steps.current[currentStep.current];
			if (step) {
				const action = step[0];
				switch (action) {
					case 'add_shield':
						schields.current[shieldCount.current++] = [
							false,
							parseFloat(step[1]),
							parseFloat(step[2]),
							parseFloat(step[3]),
						];
						break;
					case 'act_shield':
						schields.current[schieldAct.current++][0] = true;
						break;
					case 'sub_shield':
						schields.current[schieldSub.current++][0] = false;
						break;
					case 'set_brush':
						brushSize.current = parseFloat(step[1]);
						brushSoft.current = parseFloat(step[2]);
						break;
					case 'set_offset':
						offset.current = {
							x: parseFloat(step[1]),
							y: parseFloat(step[2]),
						};
						break;
					default:
						const x = parseFloat(step[0]) + offset.current.x;
						const y = parseFloat(step[1]) + offset.current.y;
						const rr = Math.floor(2 * brushSize.current) + 1;
						const imgData = context.current.getImageData(
							Math.floor(x - brushSize.current),
							Math.floor(y - brushSize.current),
							rr,
							rr
						);
						const imgData2 = context2.current.getImageData(
							Math.floor(x - brushSize.current - offset.current.x),
							Math.floor(y - brushSize.current - offset.current.y),
							rr,
							rr
						);
						const sc = Math.max(1, Math.min(100, 100 / brushSoft.current));
						for (let i = 0; i < imgData2.data.length; i += 4) {
							const a = (i / 4) % rr;
							const b = i / 4 / rr;
							const c =
								1 -
								Math.sqrt(
									(a - brushSize.current) * (a - brushSize.current) +
										(b - brushSize.current) * (b - brushSize.current)
								) /
									brushSize.current;
							let w = true;
							if (schieldAct.current > schieldSub.current) {
								for (let s = schieldSub.current; s < schieldAct.current; s++) {
									if (
										Math.pow(a + (x - brushSize.current - offset.current.x - schields.current[s][1]), 2) +
											Math.pow(b + (y - brushSize.current - offset.current.y - schields.current[s][2]), 2) <
										Math.pow(schields.current[s][3], 2)
									) {
										w = false;
									}
								}
							}
							if (w == true) {
								if (imgData2.data[i + 0] == 0 && imgData2.data[i + 1] == 0 && imgData2.data[i + 2] == 0) {
									imgData2.data[i + 0] = imgData.data[i + 0];
									imgData2.data[i + 1] = imgData.data[i + 1];
									imgData2.data[i + 2] = imgData.data[i + 2];
								}
								imgData2.data[i + 3] = Math.max(
									imgData.data[i + 3],
									Math.min(imgData2.data[i + 3], c * 255 * sc)
								);
							} else {
								imgData2.data[i + 0] = imgData.data[i + 0];
								imgData2.data[i + 1] = imgData.data[i + 1];
								imgData2.data[i + 2] = imgData.data[i + 2];
								imgData2.data[i + 3] = imgData.data[i + 3];
							}
						}
						context.current.putImageData(
							imgData2,
							Math.floor(x - brushSize.current),
							Math.floor(y - brushSize.current)
						);
						break;
				}
			} else {
				setCanvases(canvases => {
					return canvases.map(c => {
						if (c.area._id === activeArea.current?._id) {
							return {
								...c,
								isDrawing: false,
								isDrawn: true,
							};
						}
						return c;
					});
				});
				if (bookActive) {
					dispatch(
						reducers.interaktiv.bookActive.actions.set({
							...bookActive,
							canvas: {
								drawing: undefined,
								speed: intervalMs.current === drawingSpeed.normal ? 'normal' : 'fast',
							},
						})
					);
				}
			}
			currentStep.current++;
		}
	};

	const animate = (area: Types.AreaDocument) => {
		if (bookActive) {
			activeArea.current = area;
			setCanvases(canvases => {
				return canvases.map(c => {
					if (c.area._id === area._id) {
						return {
							...c,
							isDrawing: true,
						};
					}
					return { ...c, isDrawing: false };
				});
			});
			currentStep.current = 0;
			offset.current = {
				x: 0,
				y: 0,
			};
			schields.current = [];
			shieldCount.current = 0;
			schieldAct.current = 0;
			schieldSub.current = 0;
			brushSize.current = 10;
			brushSoft.current = 10;
			steps.current = JSON.parse('[' + area.canvas + ']');

			const interval = setInterval(drawStep, intervalMs.current);
			setDrawingInterval(interval);
		}
	};

	// get canvas contexts
	useEffect(() => {
		const canvas = canvasRef.current;
		const canvas2 = canvasRef2.current;
		if (canvas && canvas2) {
			context.current = canvas.getContext('2d', { willReadFrequently: false });
			context2.current = canvas2.getContext('2d', { willReadFrequently: false });
		}
	}, [canvasRef, canvasRef2]);

	// filter new canvases on page change
	useEffect(() => {
		setCanvases(
			page.areas
				.filter(a => a.type === 'canvas')
				.map(area => ({
					isDrawing: false,
					isDrawn: false,
					area,
				}))
		);
	}, [page]);

	// load layers on mount
	useEffect(() => {
		loadLayers().then(() => {
			const overlay = overlays.current[0];
			if (overlay && context2.current) {
				context2.current.drawImage(overlay, 0, 0, canvasWidth, canvasHeight);
			}
			setLoading(false);
		});
	}, []);

	// clear drawing interval on component unmount
	useEffect(() => {
		if (drawingInterval) {
			return () => {
				clearInterval(drawingInterval);
			};
		}
	}, [drawingInterval]);

	useEffect(() => {
		if (bookActiveCanvas && bookActiveCanvas.drawing) {
			const canvas = canvases.find(c => c.area._id === bookActiveCanvas.drawing);
			if (canvas) {
				if (canvas.isDrawing) {
					intervalMs.current =
						bookActiveCanvas.speed === 'normal' ? drawingSpeed.normal : drawingSpeed.fast;
					const interval = setInterval(drawStep, intervalMs.current);
					setDrawingInterval(interval);
				} else {
					animate(canvas.area);
				}
			} else if (drawingInterval) {
				dispatch(
					reducers.interaktiv.bookActive.actions.set({
						...bookActive,
						canvas: {
							speed: intervalMs.current === drawingSpeed.normal ? 'fast' : 'normal',
						},
					})
				);
				clearInterval(drawingInterval);
			}
		}
	}, [bookActiveCanvas]);

	return bookActive && canvases.length > 0 ? (
		<>
			{!loading &&
				canvases.map(canvas => {
					const canvasWidth = canvas.area.position.right - canvas.area.position.left;
					const canvasHeight = canvas.area.position.bottom - canvas.area.position.top;

					return (
						<div
							key={`canvas-${canvas.area._id}`}
							style={{
								cursor: canvas.isDrawn ? 'default' : 'pointer',
								top: `${bookActive.pageDimensions.height * (canvas.area.position.top / 100)}px`,
								left: `${bookActive.pageDimensions.width * (canvas.area.position.left / 100)}px`,
								width: `${bookActive.pageDimensions.width * (canvasWidth / 100)}px`,
								height: `${bookActive.pageDimensions.height * (canvasHeight / 100)}px`,
								zIndex: 8,
							}}
							className={`area canvas`}
							onClick={() => {
								if (!canvas.isDrawing && !canvas.isDrawn) {
									intervalMs.current = drawingSpeed.normal;
									dispatch(
										reducers.interaktiv.bookActive.actions.set({
											...bookActive,
											canvas: {
												drawing: canvas.area._id,
												speed: 'normal',
											},
										})
									);
								}
								if (canvas.isDrawing) {
									dispatch(
										reducers.interaktiv.bookActive.actions.set({
											...bookActive,
											canvas: {
												drawing: canvas.area._id,
												speed: intervalMs.current === drawingSpeed.normal ? 'fast' : 'normal',
											},
										})
									);
								}
							}}
						></div>
					);
				})}
			<canvas
				ref={canvasRef}
				width={canvasWidth}
				height={canvasHeight}
				style={{
					position: 'absolute',
					width: bookActive.pageDimensions.width,
					height: bookActive.pageDimensions.height,
				}}
			/>
			<canvas
				ref={canvasRef2}
				width={canvasWidth}
				height={canvasHeight}
				style={{
					display: 'none',
				}}
			/>
		</>
	) : null;
};

export default connect((state: RootState) => ({
	bookActive: state.bookActive,
}))(Canvas);

import Box from '@mui/material/Box';
import Types from '@taktik/common/types';
import { useEffect, useRef } from 'react';
import { connect } from 'react-redux';

interface AudioPlayerProps {
	bookActive: Types.BookActiveProps;
}

const AudioPlayer = (props: AudioPlayerProps) => {
	const bookActive = props.bookActive;
	const bookAudio = bookActive?.audio;
	const filesPath = process.env.REACT_APP_FILES || '';
	const audioRef = useRef<HTMLAudioElement>(null);

	useEffect(() => {
		if (audioRef.current) {
			audioRef.current.pause();
			audioRef.current.load();
			audioRef.current.play();
		}
	}, [bookAudio]);

	return bookAudio ? (
		<Box
			sx={{
				position: 'absolute',
				top: 6,
				left: 'calc(50% - 150px)',
				zIndex: 10,
			}}
		>
			<audio
				controls
				controlsList="nodownload"
				autoPlay
				ref={audioRef}
				style={{ width: 300, height: 36 }}
			>
				<source src={`${filesPath}${bookAudio.ogg}`} type="audio/ogg" />
				<source src={`${filesPath}${bookAudio.mp3}`} type="audio/mpeg" />
			</audio>
		</Box>
	) : null;
};

export default connect((state: Types.RootState) => ({
	bookActive: state.bookActive,
}))(AudioPlayer);

import Types from '@taktik/common/types';
import { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import Reducers from '@taktik/common/reducers';
import AreaDialog from './AreaDialog';

interface AreasProps {
	user: Types.UserSession;
	page: Types.BookPageDocument;
	bookActive: Types.BookActiveProps;
	layer?: number;
}

const Areas = (props: AreasProps) => {
	const dispatch = useDispatch();
	const page = props.page;
	const bookActive = props.bookActive;
	const selected = bookActive?.editor.selected;
	const mode = bookActive?.editor?.mode;
	const tool = bookActive?.editor?.tool;
	const bookTransformMode = bookActive?.editor.transform.mode;
	const [selectedType, setSelectedType] = useState<Types.AreaTypes>();
	const [areas, setAreas] = useState<Types.AreaDocument[]>([]);
	const [mousedown, setMousedown] = useState(false);
	const [mouseOrigin, setMouseOrigin] = useState({ x: 0, y: 0 });
	const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
	const [areaDetail, setAreaDetail] = useState<Types.AreaDocument>();
	const [connectArea, setConnectArea] = useState<number>();

	const addArea = (position: Types.Area['position']) => {
		if (bookActive) {
			const newArea: Omit<Types.Area, 'id'> = {
				versionId: 1,
				exerciseId: 1,
				type: 'both',
				position,
				fontSize: 1,
				layer: props.layer || 0,
				canvas: '',
				results: [],
				options: [],
				group: 0,
				sort: 0,
			};
			dispatch(
				Reducers.interaktiv.bookPages.actions.update(
					'add/area',
					{
						pageId: page._id,
						data: {
							value: newArea,
						},
					},
					props.user.token
				)
			);
		}
	};

	const duplicateArea = (
		area: Types.AreaDocument,
		direction: 'left' | 'right' | 'top' | 'bottom'
	) => {
		const width = area.position.right - area.position.left;
		const height = area.position.bottom - area.position.top;

		let newPosition: Types.Area['position'] = area.position;
		switch (direction) {
			case 'left':
				newPosition = {
					top: area.position.top,
					bottom: area.position.bottom,
					left: area.position.left - width,
					right: area.position.left,
				};
				break;
			case 'right':
				newPosition = {
					top: area.position.top,
					bottom: area.position.bottom,
					left: area.position.right,
					right: area.position.right + width,
				};
				break;
			case 'top':
				newPosition = {
					top: area.position.top - height,
					bottom: area.position.top,
					left: area.position.left,
					right: area.position.right,
				};
				break;
			case 'bottom':
				newPosition = {
					top: area.position.bottom,
					bottom: area.position.bottom + height,
					left: area.position.left,
					right: area.position.right,
				};
				break;
		}

		const newArea: Omit<Types.Area, 'id'> = {
			position: newPosition,
			type: area.type,
			canvas: area.canvas,
			versionId: 1,
			exerciseId: area.exerciseId,
			layer: area.layer,
			results: area.results,
			options: area.options,
			group: area.group,
			fontSize: area.fontSize,
			sort: area.sort,
		};
		dispatch(
			Reducers.interaktiv.bookPages.actions.update(
				'add/area',
				{
					pageId: page._id,
					data: {
						value: newArea,
					},
				},
				props.user.token
			)
		);
	};

	const setSelected = (newSelection: number[], mode?: Types.BookEditTransformModes) => {
		if (bookActive) {
			dispatch(
				Reducers.interaktiv.bookActive.actions.set({
					...bookActive,
					editor: {
						...bookActive.editor,
						selected: newSelection,
						transform: {
							...bookActive.editor.transform,
							mode: mode || 'areaMove',
						},
					},
				})
			);
		}
	};

	const setTransformMode = (mode: Types.BookEditTransformModes) => {
		if (bookActive) {
			dispatch(
				Reducers.interaktiv.bookActive.actions.set({
					...bookActive,
					editor: {
						...bookActive.editor,
						transform: {
							...bookActive.editor.transform,
							mode,
						},
					},
				})
			);
		}
	};

	const setControls = (active: boolean) => {
		if (bookActive) {
			dispatch(
				Reducers.interaktiv.bookActive.actions.set({
					...bookActive,
					controls: active,
				})
			);
		}
	};

	useEffect(() => {
		setAreas(page.areas);
		if (areaDetail) {
			const newAreaDetail = page.areas.find(a => a._id === areaDetail._id);
			setControls(false);
			setAreaDetail(newAreaDetail);
		}
	}, [page.areas]);

	// mousedown events
	useEffect(() => {
		if (bookActive && mode === 'areas' && (tool === 'select' || tool === 'add')) {
			const onMousedown = (event: MouseEvent) => {
				setMouseOrigin({ x: event.clientX, y: event.clientY });
				setMousedown(true);
			};
			const onMouseup = () => {
				setMousedown(false);
			};

			if (bookTransformMode === 'areaMove' || bookTransformMode === 'areaResize') {
				if (tool === 'select') {
					addEventListener('mousedown', onMousedown);
				}
				addEventListener('mouseup', onMouseup);
			} else {
				setMousedown(false);
				removeEventListener('mousedown', onMousedown);
				removeEventListener('mouseup', onMouseup);
			}

			return () => {
				removeEventListener('mousedown', onMousedown);
				removeEventListener('mouseup', onMouseup);
			};
		}
	}, [mode, bookTransformMode]);

	// mousemove events
	useEffect(() => {
		if (bookActive && mode === 'areas' && (tool === 'select' || tool === 'add')) {
			const onMousemove = (event: MouseEvent) => {
				if (bookActive) {
					setMouseOrigin(mouseOrigin => {
						const addResizeMode = bookTransformMode === 'areaResize';
						const originX = bookActive.editor.transform.x + mouseOrigin.x;
						const originY = bookActive.editor.transform.y + mouseOrigin.y;
						const positionX = event.clientX + mousePosition.x;
						const positionY = event.clientY + mousePosition.y;
						const newX = originX - positionX;
						const newY = originY - positionY;
						const newPosition = {
							x: newX,
							y: newY,
						};

						if (addResizeMode) {
							const direction =
								newX <= 0 && newY <= 0
									? 'rb'
									: newX <= 0 && newY > 0
									? 'rt'
									: newX > 0 && newY <= 0
									? 'lb'
									: 'lt';

							// add area mode
							switch (direction) {
								case 'rb':
									break;
								case 'rt':
									newPosition.y = (originY / bookActive.pageDimensions.height) * 100;
									setAreas(areas =>
										areas.map(area => {
											if (area.id === 1) {
												area.position.top = (positionY / bookActive.pageDimensions.height) * 100;
												return area;
											}
											return area;
										})
									);
									break;
								case 'lb':
									break;
								case 'lt':
									break;
							}
						}

						setMousePosition(newPosition);

						return mouseOrigin;
					});
				}
			};

			removeEventListener('mousemove', onMousemove);
			if (mousedown && (bookTransformMode === 'areaMove' || bookTransformMode === 'areaResize')) {
				addEventListener('mousemove', onMousemove);
			}

			return () => {
				removeEventListener('mousemove', onMousemove);
			};
		}
	}, [mousedown, bookTransformMode]);

	// keyboard events
	useEffect(() => {
		if (bookActive && mode === 'areas' && tool === 'select') {
			const handleKeypress = (event: KeyboardEvent) => {
				const key = event.key;
				if (key === 'Backspace' || key === 'Delete') {
					if (selected?.[0] && bookActive.controls) {
						const area = areas.find(a => a.id === selected?.[0]);
						if (area) {
							dispatch(
								Reducers.interaktiv.bookPages.actions.update(
									'remove/area',
									{
										pageId: page._id,
										data: {
											areaId: area._id,
										},
									},
									props.user.token
								)
							);
						}
					}
				}
			};
			addEventListener('keydown', handleKeypress);
			return () => {
				removeEventListener('keydown', handleKeypress);
			};
		}
	}, [bookActive]);

	return (
		<div className="areas">
			{tool === 'add' && (
				<div
					style={{
						width: bookActive?.pageDimensions.width,
						height: bookActive?.pageDimensions.height,
						cursor: 'crosshair',
					}}
					onMouseDown={event => {
						setMouseOrigin({ x: event.clientX, y: event.clientY });
						setMousedown(true);
						setSelected([1], 'areaResize');
						if (bookActive) {
							const offset = event.currentTarget.getBoundingClientRect();
							const left = ((event.clientX - offset.left) / bookActive.pageDimensions.width) * 100;
							const top = ((event.clientY - offset.top) / bookActive.pageDimensions.height) * 100;
							const newArea: Types.AreaDocument = {
								_id: 'new',
								id: 1,
								versionId: 1,
								exerciseId: 1,
								position: {
									left,
									right: left,
									top,
									bottom: top,
								},
								type: 'both',
								layer: props.layer || 0,
								results: [],
								options: [],
								group: 0,
								canvas: '',
								fontSize: 1,
								sort: 0,
							};
							setAreas([...areas, newArea]);
						}
					}}
					onMouseUp={event => {
						if (bookActive) {
							const newArea = areas.find(a => a.id === 1);
							if (newArea) {
								const offset = event.currentTarget.getBoundingClientRect();
								const right = ((event.clientX - offset.left) / bookActive.pageDimensions.width) * 100;
								const bottom = ((event.clientY - offset.top) / bookActive.pageDimensions.height) * 100;
								newArea.position = {
									left: newArea.position.left,
									top: newArea.position.top,
									right,
									bottom,
								};
								addArea(newArea.position);
								setAreas(areas.filter(a => a.id !== 1));
								setMousePosition({ x: 0, y: 0 });
								setMouseOrigin({ x: 0, y: 0 });
							}
						}
					}}
				></div>
			)}
			{bookActive &&
				areas.map(area => {
					if (props.layer !== undefined && area.layer !== props.layer) {
						return null;
					}

					const bookEditorZoom = bookActive.editor.transform.z;
					const areaWidth = area.position.right - area.position.left;
					const areaHeight = area.position.bottom - area.position.top;
					const areaPositionleft = bookActive.pageDimensions.width * (area.position.left / 100);
					const areaPositionTop = bookActive.pageDimensions.height * (area.position.top / 100);
					const isSelected = selected && selected.indexOf(area.id) > -1;
					const isSelection = selected && selected[0] === area.id;
					const overlay = `${process.env.REACT_APP_FILES}${page.overlays[area.layer]}`;

					return (
						<div key={`area-${area._id}`}>
							<div
								id={`area-${area.id}`}
								style={{
									cursor: tool === 'select' ? 'move' : tool === 'add' ? 'crosshair' : 'default',
									backgroundImage: area.type === 'active' ? 'none' : `url(${overlay})`,
									backgroundColor:
										area.type === 'active'
											? 'rgba(0,255,0,0.5)'
											: area.type === 'both'
											? 'rgba(0, 94, 255, 0.3)'
											: area.type === 'canvas'
											? 'rgb(238, 0, 255, 0.3)'
											: 'rgba(242, 255, 0, 0.3)',
									border: isSelected
										? '2px solid red'
										: area.type === 'active'
										? '2px dashed #00ff00'
										: area.type === 'both'
										? '2px dashed #005eff'
										: area.type === 'canvas'
										? '2px dashed #ee00ff'
										: '2px dashed #ff7300',
									marginTop: -2,
									marginLeft: -2,
									backgroundPosition:
										isSelection && bookTransformMode === 'areaMove'
											? `-${areaPositionleft - mousePosition.x / bookEditorZoom}px -${
													areaPositionTop - mousePosition.y / bookEditorZoom
											  }px`
											: `-${areaPositionleft}px -${areaPositionTop}px`,
									backgroundSize: `${bookActive.pageDimensions.width}px ${bookActive.pageDimensions.height}px`,
									backgroundRepeat: 'no-repeat',
									top:
										isSelection && bookTransformMode === 'areaMove'
											? `${areaPositionTop - mousePosition.y / bookEditorZoom}px`
											: `${areaPositionTop}px`,
									left:
										isSelection && bookTransformMode === 'areaMove'
											? `${areaPositionleft - mousePosition.x / bookEditorZoom}px`
											: `${areaPositionleft}px`,
									width:
										isSelection && bookTransformMode === 'areaResize'
											? `${
													bookActive.pageDimensions.width * (areaWidth / 100) - mousePosition.x / bookEditorZoom
											  }px`
											: `${bookActive.pageDimensions.width * (areaWidth / 100)}px`,
									height:
										isSelection && bookTransformMode === 'areaResize'
											? `${
													bookActive.pageDimensions.height * (areaHeight / 100) -
													mousePosition.y / bookEditorZoom
											  }px`
											: `${bookActive.pageDimensions.height * (areaHeight / 100)}px`,
									zIndex: isSelection ? 10 : area.type === 'active' ? 9 : 1,
									opacity: tool === 'add' ? 1 : 0.6,
								}}
								className={`area ${area.type}`}
								onClick={() => {
									if (tool === 'connect') {
										if (connectArea) {
											dispatch(
												Reducers.interaktiv.bookPages.actions.update(
													'add/areaResult',
													{
														pageId: page._id,
														data: {
															areaId: area._id,
															value: connectArea,
														},
													},
													props.user.token
												)
											);
											setConnectArea(undefined);
										} else {
											setSelected([area.id, ...area.results]);
											setSelectedType(area.type);
										}
									}
									if (
										tool === 'select' &&
										bookTransformMode === 'areaMove' &&
										(mousePosition.x !== 0 || mousePosition.y !== 0)
									) {
										const top =
											((areaPositionTop - mousePosition.y / bookEditorZoom) /
												bookActive.pageDimensions.height) *
											100;
										const left =
											((areaPositionleft - mousePosition.x / bookEditorZoom) /
												bookActive.pageDimensions.width) *
											100;
										const newPosition: Types.Area['position'] = {
											top,
											left,
											bottom: top + areaHeight,
											right: left + areaWidth,
										};
										setSelected([area.id]);
										setSelectedType(area.type);
										setMousePosition({ x: 0, y: 0 });
										setMouseOrigin({ x: 0, y: 0 });
										setAreas(areas.map(a => (a._id === area._id ? { ...a, position: newPosition } : a)));
										dispatch(
											Reducers.interaktiv.bookPages.actions.update(
												'update/areaPosition',
												{
													pageId: page._id,
													data: {
														areaId: area._id,
														value: newPosition,
													},
												},
												props.user.token
											)
										);
									}
								}}
								onMouseEnter={() => {
									if (tool === 'select' && bookTransformMode !== 'areaResize' && !mousedown) {
										setTransformMode('areaMove');
									}
									if (tool === 'select' && !mousedown) {
										setSelected([area.id]);
									}
								}}
								onMouseLeave={() => {
									if (!mousedown && bookTransformMode !== 'areaResize') {
										setTransformMode('workspace');
										setMousePosition({ x: 0, y: 0 });
										setMouseOrigin({ x: 0, y: 0 });
									}
								}}
								onDoubleClick={() => {
									setControls(false);
									setAreaDetail(area);
								}}
							>
								{tool === 'connect' && isSelection && (area.type === 'active' || area.type === 'both') && (
									<div
										style={{
											position: 'absolute',
											left: -12,
											top: 'calc(50% - 5px)',
											width: 8,
											height: 8,
											backgroundColor: connectArea ? 'red' : 'black',
											cursor: 'copy',
										}}
										onClick={() => setConnectArea(area.id)}
										className="duplicate-area-right"
									></div>
								)}
								{tool === 'select' && isSelection && (
									<>
										<div
											style={{
												position: 'absolute',
												right: -12,
												top: 'calc(50% - 5px)',
												width: 8,
												height: 8,
												backgroundColor: 'black',
												cursor: 'copy',
											}}
											onClick={() => duplicateArea(area, 'right')}
											className="duplicate-area-right"
										></div>
										<div
											style={{
												position: 'absolute',
												left: -12,
												top: 'calc(50% - 5px)',
												width: 8,
												height: 8,
												backgroundColor: 'black',
												cursor: 'copy',
											}}
											onClick={() => duplicateArea(area, 'left')}
											className="duplicate-area-left"
										></div>
										<div
											style={{
												position: 'absolute',
												top: -12,
												left: 'calc(50% - 5px)',
												width: 8,
												height: 8,
												backgroundColor: 'black',
												cursor: 'copy',
											}}
											onClick={() => duplicateArea(area, 'top')}
											className="duplicate-area-top"
										></div>
										<div
											style={{
												position: 'absolute',
												bottom: -12,
												left: 'calc(50% - 5px)',
												width: 8,
												height: 8,
												backgroundColor: 'black',
												cursor: 'copy',
											}}
											onClick={() => duplicateArea(area, 'bottom')}
											className="duplicate-area-bottom"
										></div>
										<div
											style={{
												position: 'absolute',
												right: -10,
												bottom: -10,
												width: 8,
												height: 8,
												backgroundColor: 'black',
												cursor: 'nwse-resize',
											}}
											onClick={() => {
												if (bookTransformMode === 'areaResize') {
													const top = area.position.top;
													const left = area.position.left;
													const positionBottom =
														top +
														((bookActive.pageDimensions.height * (areaHeight / 100) -
															mousePosition.y / bookEditorZoom) /
															bookActive.pageDimensions.height) *
															100;
													const positionRight =
														left +
														((bookActive.pageDimensions.width * (areaWidth / 100) -
															mousePosition.x / bookEditorZoom) /
															bookActive.pageDimensions.width) *
															100;
													const newPosition: Types.Area['position'] = {
														top,
														left,
														bottom: positionBottom,
														right: positionRight,
													};
													setMousePosition({ x: 0, y: 0 });
													setMouseOrigin({ x: 0, y: 0 });
													setAreas(areas.map(a => (a._id === area._id ? { ...a, position: newPosition } : a)));
													dispatch(
														Reducers.interaktiv.bookPages.actions.update(
															'update/areaPosition',
															{
																pageId: page._id,
																data: {
																	areaId: area._id,
																	value: newPosition,
																},
															},
															props.user.token
														)
													);
												}
											}}
											onMouseEnter={() => {
												setTransformMode('areaResize');
											}}
											onMouseLeave={() => {
												if (!mousedown && bookTransformMode !== 'areaMove') {
													setTransformMode('workspace');
													setMousePosition({ x: 0, y: 0 });
													setMouseOrigin({ x: 0, y: 0 });
												}
											}}
											className="area-resize"
										></div>
									</>
								)}
							</div>
						</div>
					);
				})}
			{areaDetail && (
				<AreaDialog
					page={page}
					area={areaDetail}
					onClose={() => {
						setControls(true);
						setAreaDetail(undefined);
					}}
					open={areaDetail !== undefined}
				/>
			)}
		</div>
	);
};

export default connect((state: Types.RootState) => ({
	user: state.user,
	bookActive: state.bookActive,
}))(Areas);

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import ModeIcon from '@mui/icons-material/ModeOutlined';
import EmailIcon from '@mui/icons-material/EmailOutlined';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCartOutlined';
import EditNoteIcon from '@mui/icons-material/NoteAdd';
import AutoFixNormalIcon from '@mui/icons-material/AutoFixNormal';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import reducers from '@taktik/common/reducers';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import Types from '@taktik/common/types';
import makeStyles from '@mui/styles/makeStyles';
import FeedbackDialog from './FeedbackDialog';
import TestEditorDialog from './TestEditorDialog';
import BookSlider from './BookSlider';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import theme from '../../../theme';
import TaktikIcon from '@taktik/components/TaktikIcon';

const useStyles = makeStyles({
	brush: {
		opacity: 0.9,
		'&:hover': {
			opacity: 1,
		},
	},
});

const brushColors = [
	'#974806',
	'#fe0000',
	'#ff6201',
	'#ffff00',
	'#00af50',
	'#00aca0',
	'#0071c1',
	'#9900cc',
	'#fe66cb',
	'#000000',
	'#bfbfbf',
];

const brushSizes = [4, 10, 20];

const styles = {
	icon: {
		border: '1px solid #ddd',
		color: '#fff',
		background: '#02253e',
		'&:hover': {
			background: '#fff',
			color: '#02253e',
		},
	},
	iconAudio: {
		color: '#fff',
		background: '#02253e',
		'.icon-button': {
			borderRadius: '50%',
			padding: '8px 12px',
			background: '#05253e',
			margin: '1px',
		},
		'&:hover': {
			'.icon-button': {
				background: 'none',
			},
		},
	},
	iconActive: {
		border: '1px solid #fff',
		background: '#fff',
		color: '#02253e',
		'&:hover': {
			background: '#fff',
		},
	},
};

interface BookOptionsProps {
	books: Types.BookDocument[];
	bookActive: Types.BookActiveProps;
	hasUaAudio: boolean;
}

const BookOptions = (props: BookOptionsProps) => {
	const dispatch = useDispatch();
	const classes = useStyles();
	const books = props.books;
	const bookActive = props.bookActive;
	const legendOpen = bookActive?.legend;
	const bonusesOpen = bookActive?.bonuses;
	const [book, setBook] = useState<Types.BookDocument>();
	const [pencilHover, setPencilHover] = useState(false);
	const [showContactDialog, setShowContactDialog] = useState(false);
	const [showTestEditorDialog, setShowTestEditorDialog] = useState(false);

	const setMode = (
		mode: 'pencil' | 'eraser',
		toggle: boolean,
		brush?: { color: string; size: number }
	) => {
		if (bookActive) {
			dispatch(
				reducers.interaktiv.bookActive.actions.set({
					...bookActive,
					drawing: {
						...bookActive.drawing,
						...brush,
						active: toggle
							? bookActive.drawing.active && bookActive.drawing.mode === mode
								? false
								: true
							: true,
						mode,
					},
				})
			);
		}
	};

	useEffect(() => {
		setBook(books.find(b => b._id === bookActive?.bookId));
	}, [bookActive, books]);

	return bookActive ? (
		<Box
			mx={3}
			sx={{
				position: 'absolute',
				bottom: theme.spacing(2),
				left: legendOpen ? 280 : 0,
				right: bonusesOpen ? 320 : 0,
				transition: 'all 0.5s',
			}}
		>
			<Grid container justifyContent="space-between" alignItems="center" flexWrap="nowrap">
				<Grid item width={350}>
					<Box component="span" mr={2}>
						<Button
							sx={styles.icon}
							style={{ zIndex: 10, borderRadius: '4px 30px 30px 4px', paddingRight: 16 }}
							size="large"
							onClick={() => {
								if (bookActive) {
									dispatch(
										reducers.interaktiv.bookActive.actions.set({
											...bookActive,
											legend: !bookActive?.legend,
										})
									);
								}
							}}
							startIcon={bookActive?.legend ? <ArrowBackIosNewIcon /> : <ArrowForwardIosIcon />}
						>
							Obsah
						</Button>
					</Box>
					<Tooltip title="Guma">
						<IconButton
							sx={
								bookActive && bookActive.drawing.active && bookActive.drawing.mode === 'eraser'
									? styles.iconActive
									: styles.icon
							}
							style={{ zIndex: 8 }}
							size="large"
							onClick={() => setMode('eraser', true)}
						>
							<TaktikIcon icon="eraser" size={24} />
						</IconButton>
					</Tooltip>
					<Box
						component="span"
						ml={2}
						position="relative"
						onMouseOver={() => setPencilHover(true)}
						onMouseLeave={() => setPencilHover(false)}
					>
						{brushColors.map((color, i) => (
							<div
								key={`color-${i}`}
								style={{
									zIndex: 9,
									position: 'absolute',
									left: 26,
									top: 10,
									transform: `rotate(${i * 15 - 55}deg)`,
									width: 10,
								}}
							>
								{brushSizes.map((size, n) => (
									<div
										key={`color-${i}-${n}`}
										style={{
											transition: '0.3s all',
											position: 'absolute',
											marginTop: `-${pencilHover ? (n === 0 ? 50 : n === 1 ? 78 : 120) : 0}px`,
											border: '1px solid #fff',
											background: color,
											width: pencilHover ? (n === 0 ? 15 : n === 1 ? 23 : 38) : 0,
											height: pencilHover ? (n === 0 ? 15 : n === 1 ? 23 : 38) : 0,
											borderRadius: `100%`,
										}}
										className={classes.brush}
										onClick={() => {
											setMode('pencil', false, { color, size });
											setPencilHover(false);
										}}
									></div>
								))}
							</div>
						))}
						<IconButton
							sx={
								bookActive && bookActive.drawing.active && bookActive.drawing.mode === 'pencil'
									? styles.iconActive
									: styles.icon
							}
							style={{ zIndex: 10 }}
							size="large"
							onClick={() => setMode('pencil', true)}
						>
							<TaktikIcon icon="pencil" size={24} />
						</IconButton>
					</Box>
					{props.hasUaAudio && (
						<Box component="span" ml={2}>
							<Tooltip title="UA Audio">
								<IconButton
									sx={{
										...styles.iconAudio,
										zIndex: 8,
										background:
											'linear-gradient(0deg, rgba(255,213,0,1) 0%, rgba(255,213,0,1) 50%, rgba(1,90,187,1) 50%, rgba(1,90,187,1) 100%)',
										padding: 0,
									}}
									size="large"
									onClick={() =>
										dispatch(
											reducers.interaktiv.bookActive.actions.set({
												...bookActive,
												uaAudio: !props.bookActive?.uaAudio,
											})
										)
									}
								>
									<span
										className="icon-button"
										style={{ background: bookActive.uaAudio ? 'none' : undefined }}
									>
										<TaktikIcon icon="note" size={24} />
									</span>
								</IconButton>
							</Tooltip>
						</Box>
					)}
					{bookActive && bookActive.canvas.drawing && (
						<Box component="span" ml={2}>
							<IconButton
								size="large"
								sx={bookActive.canvas.speed === 'normal' ? styles.icon : styles.iconActive}
							>
								<DoubleArrowIcon />
							</IconButton>
						</Box>
					)}
				</Grid>
				<Grid item>
					<BookSlider />
				</Grid>
				<Grid item width={350} textAlign="right">
					{book?.test && (
						<Box component="span" mr={2}>
							<IconButton sx={styles.icon} size="large" onClick={() => setShowTestEditorDialog(true)}>
								<EditNoteIcon fontSize="medium" />
							</IconButton>
						</Box>
					)}
					<Box component="span" mr={2}>
						<Tooltip title="Kontakt">
							<IconButton sx={styles.icon} size="large" onClick={() => setShowContactDialog(true)}>
								<EmailIcon fontSize="medium" />
							</IconButton>
						</Tooltip>
					</Box>
					{book?.eshopUrl && (
						<Box component="span" mr={2}>
							<Tooltip title="E-shop">
								<IconButton
									sx={styles.icon}
									size="large"
									onClick={() => window.open(book.eshopUrl, '_blank')}
								>
									<ShoppingCartIcon fontSize="medium" />
								</IconButton>
							</Tooltip>
						</Box>
					)}
					<Button
						sx={styles.icon}
						style={{ zIndex: 10, borderRadius: '30px 4px 4px 30px', paddingLeft: 16 }}
						size="large"
						onClick={() => {
							if (bookActive) {
								dispatch(
									reducers.interaktiv.bookActive.actions.set({
										...bookActive,
										bonuses: !bookActive?.bonuses,
									})
								);
							}
						}}
						endIcon={bookActive?.bonuses ? <ArrowForwardIosIcon /> : <ArrowBackIosNewIcon />}
					>
						Bonusy
					</Button>
				</Grid>
			</Grid>
			{showContactDialog && <FeedbackDialog onClose={() => setShowContactDialog(false)} />}
			{showTestEditorDialog && <TestEditorDialog onClose={() => setShowTestEditorDialog(false)} />}
		</Box>
	) : null;
};

export default connect((state: Types.RootState) => ({
	books: state.books,
	bookActive: state.bookActive,
}))(BookOptions);

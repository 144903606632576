import Types from '@taktik/common/types';
import Helpers from '@taktik/common/helpers';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Canvas from './Canvas';

interface AreasProps {
	bookActive: Types.BookActiveProps;
	page: Types.BookPageDocument;
}

const Areas = (props: AreasProps) => {
	const page = props.page;
	const bookActive = props.bookActive;
	const [areas, setAreas] = useState(page.areas.filter(a => a.type !== 'canvas'));
	const [activeAreas, setActiveAreas] = useState<string[]>([]);

	useEffect(() => {
		setAreas(page.areas.filter(a => a.type !== 'canvas'));
	}, [page]);

	return (
		<div className="areas">
			{bookActive &&
				areas.map(area => {
					const isActive = activeAreas.indexOf(area._id) > -1;
					const areaWidth = area.position.right - area.position.left;
					const areaHeight = area.position.bottom - area.position.top;
					const overlay = `${process.env.REACT_APP_FILES}${page.overlays[area.layer]}`;

					return (
						<div key={`area-${area._id}`}>
							<div
								id={`area-${area.id}`}
								style={{
									cursor: 'pointer',
									backgroundImage: area.type === 'active' ? 'none' : `url(${overlay})`,
									backgroundPosition: `-${(area.position.left / 100) * bookActive.pageDimensions.width}px -${
										(area.position.top / 100) * bookActive.pageDimensions.height
									}px`,
									backgroundSize: `${bookActive.pageDimensions.width}px ${bookActive.pageDimensions.height}px`,
									backgroundRepeat: 'no-repeat',
									top: `${bookActive.pageDimensions.height * (area.position.top / 100)}px`,
									left: `${bookActive.pageDimensions.width * (area.position.left / 100)}px`,
									width: `${bookActive.pageDimensions.width * (areaWidth / 100)}px`,
									height: `${bookActive.pageDimensions.height * (areaHeight / 100)}px`,
									zIndex: area.type === 'active' ? 9 : 1,
									opacity: isActive ? 1 : 0,
								}}
								className={`area ${area.type}`}
								onClick={
									area.type === 'active'
										? () => {
												const newActiveAreas = [area._id];
												page.areas.forEach(a => {
													if (a.results.indexOf(area.id) > -1) {
														newActiveAreas.push(a._id);
													}
												});
												setActiveAreas(activeAreas => {
													return Helpers.uniqueArray([...activeAreas, ...newActiveAreas]);
												});
										  }
										: area.type === 'both'
										? () => {
												setActiveAreas(activeAreas => {
													const results = page.areas
														.filter(a => area.results.indexOf(a.id) > -1)
														.map(a => a._id);
													return Helpers.uniqueArray([...activeAreas, ...results, area._id]);
												});
										  }
										: undefined
								}
							></div>
						</div>
					);
				})}

			{bookActive && <Canvas page={page} />}
		</div>
	);
};

export default connect((state: Types.RootState) => ({
	bookActive: state.bookActive,
}))(Areas);

import { combineReducers } from 'redux';
import Reducers from '@taktik/common/reducers';

const rootReducer = combineReducers({
	books: Reducers.interaktiv.books.reducer,
	bookPages: Reducers.interaktiv.bookPages.reducer,
	bookActive: Reducers.interaktiv.bookActive.reducer,
	bookFeedbacks: Reducers.interaktiv.bookFeedback.reducer,
	bookCategories: Reducers.interaktiv.bookCategories.reducers,
	vouchers: Reducers.interaktiv.vouchers.reducer,
	users: Reducers.common.users.reducer,
	user: Reducers.common.user.reducer,
	subjects: Reducers.common.subjects.reducer,
	schools: Reducers.common.schools.reducer,
	classrooms: Reducers.common.classrooms.reducer,
	invites: Reducers.common.invites.reducer,
	translations: Reducers.common.translations.reducer,
	migrate: Reducers.interaktiv.migrate.reducer,
	devices: Reducers.voti.devices.reducer,
});

export default rootReducer;

import Filter1Icon from '@mui/icons-material/Filter1';
import Filter2Icon from '@mui/icons-material/Filter2';
import Filter3Icon from '@mui/icons-material/Filter3';
import Filter4Icon from '@mui/icons-material/Filter4';
import Filter5Icon from '@mui/icons-material/Filter5';
import Filter6Icon from '@mui/icons-material/Filter6';
import Box from '@mui/material/Box';
import makeStyles from '@mui/styles/makeStyles';
import Types from '@taktik/common/types';
import ArticleIcon from '@mui/icons-material/Article';
import { connect, useDispatch } from 'react-redux';
import Upload from '@taktik/components/Upload';
import Reducers from '@taktik/common/reducers';
import { useEffect } from 'react';

const useStyle = makeStyles({
	bonuses: {
		position: 'absolute',
		height: 'inherit',
	},
	bookmark: {
		backgroundColor: '#009dff',
		borderColor: '#009dff',
		position: 'absolute',
		width: '100%',
		display: 'flex',
		alignItems: 'center',
		padding: '.3em',
		cursor: 'pointer',
		'&:hover': {
			backgroundColor: '#23abff',
			borderColor: '#23abff',
			paddingRight: '.6em',
		},
		transition: 'transform .5s, padding .5s, opacity 1s',
	},
});

interface LayersEditorProps {
	user: Types.UserSession;
	bookActive: Types.BookActiveProps;
	page: Types.BookPageDocument;
	position: 'left' | 'right';
	addAreaMode?: boolean;
	iconsOnly?: boolean;
}

const LayersEditor = (props: LayersEditorProps) => {
	const dispatch = useDispatch();
	const classes = useStyle();
	const page = props.page;
	const bookActive = props.bookActive;
	const layer = bookActive?.editor.layer[props.position] || 0;
	const icons = [Filter1Icon, Filter2Icon, Filter3Icon, Filter4Icon, Filter5Icon, Filter6Icon];

	const setActiveLayer = (value: number) => {
		if (bookActive) {
			dispatch(
				Reducers.interaktiv.bookActive.actions.set({
					...bookActive,
					editor: {
						...bookActive.editor,
						layer:
							props.position === 'left'
								? { ...bookActive.editor.layer, left: value }
								: { ...bookActive.editor.layer, right: value },
					},
				})
			);
		}
	};

	useEffect(() => {
		if (layer > -1 && !page.overlays[layer]) {
			setActiveLayer(-1);
		}
	}, [layer, page]);

	if (bookActive) {
		const bookmarkWidth = bookActive.pageDimensions.width * 0.12;
		const iconSpacing = bookActive.pageDimensions.height * 0.01;
		const iconHeight = bookActive.pageDimensions.height * 0.06;
		const isCover = layer === -1;
		const image = isCover ? page.image : page.overlays[layer];

		return (
			<>
				<Box
					style={{
						position: 'absolute',
						width: bookActive.pageDimensions.width,
						height: bookActive.pageDimensions.height,
						zIndex: 9,
					}}
				>
					{!props.addAreaMode && layer !== undefined && (
						<Upload
							upload={
								image
									? {
											path: image,
											type: 'image/jpeg',
									  }
									: undefined
							}
							fileType={isCover ? 'image/jpeg' : 'image/png'}
							folder="book"
							path={isCover ? `${page.bookId}/${page._id}` : `${page.bookId}/${page._id}/overlays`}
							filename={isCover ? 'cover' : `${layer}`}
							onDelete={() => {
								if (isCover) {
									dispatch(
										Reducers.interaktiv.bookPages.actions.update(
											'update/image',
											{
												pageId: page._id,
												data: {
													value: '',
												},
											},
											props.user.token
										)
									);
								} else {
									dispatch(
										Reducers.interaktiv.bookPages.actions.update(
											'remove/overlay',
											{
												pageId: page._id,
												data: {
													value: image,
												},
											},
											props.user.token
										)
									);
								}
							}}
							onUpload={file => {
								if (isCover) {
									dispatch(
										Reducers.interaktiv.bookPages.actions.update(
											'update/image',
											{
												pageId: page._id,
												data: {
													value: file.path,
												},
											},
											props.user.token
										)
									);
								} else {
									dispatch(
										Reducers.interaktiv.bookPages.actions.update(
											'update/overlay',
											{
												pageId: page._id,
												data: {
													key: layer,
													value: file.path,
												},
											},
											props.user.token
										)
									);
								}
							}}
							cover
						/>
					)}
					{props.addAreaMode && layer !== -1 && !props.iconsOnly && (
						<img
							src={`${process.env.REACT_APP_FILES}${image}`}
							style={{
								width: '100%',
								height: '100%',
								filter: 'contrast(0) sepia(100%) hue-rotate(315deg) saturate(2000%) brightness(100%)',
								userSelect: 'none',
							}}
							draggable={false}
						/>
					)}
				</Box>
				<div
					className={classes.bonuses}
					style={{
						width: bookmarkWidth,
						height: bookActive.pageDimensions.height,
						right: props.position === 'right' ? -bookmarkWidth : 'initial',
						left: props.position === 'left' ? -bookmarkWidth : 'initial',
					}}
				>
					{!props.addAreaMode && (
						<div
							key={`overlay-${props.position}-bg`}
							style={{
								top: 0,
								left: props.position === 'left' ? '0' : 'initial',
								right: props.position === 'right' ? '0' : 'initial',
								clipPath:
									props.position === 'left'
										? 'polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%, 15% 50%)'
										: 'polygon(0% 0%, 100% 0%, 85% 50%, 100% 100%, 0% 100%)',
								justifyContent: props.position === 'left' ? 'flex-end' : 'flex-start',
								paddingTop: iconSpacing / 2,
								paddingBottom: iconSpacing / 2,
								backgroundColor: layer === -1 ? '#fff' : undefined,
								zIndex: layer === -1 ? 9 : undefined,
							}}
							className={classes.bookmark}
							onClick={() => {
								// setActive(-1);
								setActiveLayer(-1);
							}}
						>
							<ArticleIcon style={{ fontSize: iconHeight }} />
						</div>
					)}
					{page.overlays.map((overlay: string, key: number) => {
						const Icon = icons[key];

						return (
							<div
								key={`overlay-${props.position}-${key}`}
								style={{
									top: `${bookActive.pageDimensions.height * (0.1 * (key + (props.addAreaMode ? 0 : 1)))}px`,
									left: props.position === 'left' ? '0' : 'initial',
									right: props.position === 'right' ? '0' : 'initial',
									clipPath:
										props.position === 'left'
											? 'polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%, 15% 50%)'
											: 'polygon(0% 0%, 100% 0%, 85% 50%, 100% 100%, 0% 100%)',
									justifyContent: props.position === 'left' ? 'flex-end' : 'flex-start',
									paddingTop: iconSpacing / 2,
									paddingBottom: iconSpacing / 2,
									backgroundColor: layer === key ? '#fff' : undefined,
									zIndex: layer === key ? 9 : undefined,
								}}
								className={classes.bookmark}
								onClick={() => {
									setActiveLayer(key);
								}}
							>
								<Icon style={{ fontSize: iconHeight }} />
							</div>
						);
					})}
					{!props.addAreaMode && (
						<div
							style={{
								top: `${bookActive.pageDimensions.height * (0.1 * (page.overlays.length + 1))}px`,
								left: props.position === 'left' ? '0' : 'initial',
								right: props.position === 'right' ? '0' : 'initial',
								clipPath:
									props.position === 'left'
										? 'polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%, 15% 50%)'
										: 'polygon(0% 0%, 100% 0%, 85% 50%, 100% 100%, 0% 100%)',
								justifyContent: props.position === 'left' ? 'flex-end' : 'flex-start',
								paddingTop: iconSpacing / 2,
								paddingBottom: iconSpacing / 2,
							}}
							className={classes.bookmark}
						>
							<Upload
								upload={undefined}
								fileType="image/png"
								folder="book"
								path={`${page.bookId}/${page._id}/overlays`}
								filename={`${page.overlays.length}`}
								onDelete={() => {}}
								onUpload={file => {
									dispatch(
										Reducers.interaktiv.bookPages.actions.update(
											'add/overlay',
											{
												pageId: page._id,
												data: {
													key: page.overlays.length,
													value: file.path,
												},
											},
											props.user.token
										)
									);
								}}
								iconOnly
							/>
						</div>
					)}
				</div>
			</>
		);
	}
	return null;
};

export default connect((state: Types.RootState) => ({
	user: state.user,
	bookActive: state.bookActive,
}))(LayersEditor);

import { createTheme } from '@mui/material/styles';

// A custom theme for this app
const theme = createTheme({
	typography: {
		fontFamily: 'proxima-nova',
		h5: {
			fontSize: 22,
			fontWeight: '900 !important',
		},
	},
	palette: {
		primary: {
			main: '#02253e',
		},
		secondary: {
			main: '#f37416',
		},
		error: {
			main: '#d32f2f',
		},
		success: {
			main: '#689f38',
		},
		info: {
			main: '#dfe5e8',
		},
		background: {
			default: '#f5f5f6',
		},
	},
	components: {
		MuiToolbar: {
			styleOverrides: {
				root: {
					minHeight: 50,
				},
			},
		},
		MuiTypography: {
			styleOverrides: {
				root: {
					fontWeight: 400,
				},
				h1: {
					fontSize: 54,
					fontWeight: 700,
				},
			},
		},
		MuiTooltip: {
			styleOverrides: {
				tooltip: {
					fontSize: 14,
				},
			},
		},
		MuiListItemIcon: {
			styleOverrides: {
				root: {
					minWidth: 42,
				},
			},
		},
		MuiButton: {
			styleOverrides: {
				root: {
					borderRadius: 0,
				},
				containedSecondary: {
					border: '1px solid #f37416',
					color: '#fff',
					'&:hover': {
						backgroundColor: '#fff',
						borderColor: '#fff',
						color: '#f37416',
					},
				},
				containedPrimary: {
					'&:hover': {
						background: '#f37416',
					},
				},
				outlinedSecondary: {
					color: '#fff',
					borderColor: '#f37416',
					'&:hover': {
						backgroundColor: '#fff',
						borderColor: '#fff',
						color: '#f37416',
					},
				},
				sizeLarge: {
					fontSize: 16,
				},
				containedInfo: {
					color: '#02253e',
					'&:hover': {
						background: '#fff',
					},
				},
			},
		},
		MuiDialogTitle: {
			styleOverrides: {
				root: {
					padding: '32px 48px 0 48px',
					fontSize: 18,
					lineHeight: 1.2,
					fontWeight: 700,
					textTransform: 'uppercase',
					'+.MuiDialogContent-root': {
						paddingTop: '32px !important',
					},
				},
			},
		},
		MuiDialogContent: {
			styleOverrides: {
				root: {
					padding: '32px 48px',
				},
			},
		},
		MuiTabs: {
			styleOverrides: {
				root: {
					'&.school-nav': {
						'& .MuiTab-root': {
							color: '#fff',
							'&.Mui-selected': {
								background: '#f37416',
								color: '#fff',
							},
						},
					},
				},
			},
		},
		MuiSlider: {
			styleOverrides: {
				root: {
					'&.Mui-disabled': {
						color: '#f37416',
					},
				},
			},
		},
	},
});

export default theme;

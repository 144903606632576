/* eslint-disable i18next/no-literal-string */
import AppBar from '@mui/material/AppBar';
import CssBaseline from '@mui/material/CssBaseline';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { ReactElement, useEffect, useState } from 'react';
import useStyles from '../../styles';
import logo from '../../assets/taktik-logo.svg';
import theme from '../../theme';
import { connect, useDispatch } from 'react-redux';
import Types from '@taktik/common/types';
import Reducers from '@taktik/common/reducers';
import { authContext } from '@taktik/common/auth';
import BookViewOptions from './components/BookViewOptions';
import Helpers from '@taktik/common/helpers';
import { Link } from 'react-router-dom';
import i18next from 'i18next';

const dividerStyle = {
	background: '#02253e',
	width: '1px',
	height: 'calc(100% - 16px)',
	marginTop: '8px',
};

interface NavBarProps {
	children: ReactElement;
	user: Types.User;
	bookActive: Types.BookActiveProps;
	hide?: boolean;
	schools: Types.SchoolDocument[];
}

const NavBar = (props: NavBarProps) => {
	const user = props.user;
	const token = user.token;
	const bookActive = props.bookActive;
	const dispatch = useDispatch();
	const classes = useStyles();
	const [school, setSchool] = useState<Types.SchoolDocument>();

	useEffect(() => {
		if (token) {
			dispatch(Reducers.common.subjects.actions.get(token));
			dispatch(Reducers.interaktiv.bookCategories.actions.get(token));
			dispatch(Reducers.interaktiv.books.actions.get(token));
		}
	}, [token]);

	// get customers school
	useEffect(() => {
		if (user.role === Types.RoleTypesList.customer) {
			dispatch(Reducers.common.schools.actions.get(token, {}, { page: 0, pageSize: 10 }));
		}
	}, [user]);

	useEffect(() => {
		const newSchool = props.schools[0];
		setSchool(newSchool);
	}, [props.schools]);

	return (
		<div className={classes.root}>
			<CssBaseline />
			{!props.hide && (
				<AppBar
					position="fixed"
					sx={{
						zIndex: theme.zIndex.drawer + 1,
						background: '#dfe5e8',
						borderBottom: '1px solid #b2bdc4',
						boxShadow: 'none',
					}}
					color="default"
				>
					<Box px={2} py={1}>
						<Grid container spacing={3} alignItems="center" justifyContent="space-between">
							<Grid item>
								<Grid container alignItems="center" spacing={2}>
									<Grid item>
										<Grid container alignItems="center" style={{ cursor: 'pointer', height: 40 }}>
											<Grid item height={40}>
												<Link to={'/'}>
													<img src={logo} height={40} />
												</Link>
											</Grid>
										</Grid>
									</Grid>
									<Grid item>
										{user.role === Types.RoleTypesList.unauthorized && (
											<Link to={'/'}>
												<Button color="inherit" className={classes.navButton} id="nav-home">
													Interaktivita
												</Button>
											</Link>
										)}
										{user.role !== Types.RoleTypesList.unauthorized && (
											<Grid container spacing={1}>
												<Grid item>
													<Link to={'/seznam-ucebnic'}>
														<Button color="inherit" className={classes.navButton} id="nav-books">
															Interaktivní sešity
														</Button>
													</Link>
												</Grid>
												<Grid item>
													<Box sx={dividerStyle}></Box>
												</Grid>
												{[
													Types.RoleTypesList.admin.toString(),
													Types.RoleTypesList.employee.toString(),
												].indexOf(user.role) > -1 && (
													<>
														<Grid item>
															<Link to={'/schools'}>
																<Button color="inherit" className={classes.navButton}>
																	Školy
																</Button>
															</Link>
														</Grid>
														<Grid item>
															<Box sx={dividerStyle}></Box>
														</Grid>
														<Grid item>
															<Link to={'/users'}>
																<Button color="inherit" className={classes.navButton}>
																	Uživatelé
																</Button>
															</Link>
														</Grid>
														<Grid item>
															<Box sx={dividerStyle}></Box>
														</Grid>
														<Grid item>
															<Link to={'/modules'}>
																<Button color="inherit" className={classes.navButton}>
																	Moduly
																</Button>
															</Link>
														</Grid>
														<Grid item>
															<Box sx={dividerStyle}></Box>
														</Grid>
														<Grid item>
															<Link to={'/feedback'}>
																<Button color="inherit" className={classes.navButton}>
																	Korekce
																</Button>
															</Link>
														</Grid>
														<Grid item>
															<Box sx={dividerStyle}></Box>
														</Grid>
														<Grid item>
															<Link to={'/vouchers'}>
																<Button color="inherit" className={classes.navButton}>
																	Vouchery
																</Button>
															</Link>
														</Grid>
														<Grid item>
															<Box sx={dividerStyle}></Box>
														</Grid>
													</>
												)}
												{school &&
													user.role === Types.RoleTypesList.customer &&
													user.projects?.interaktiv?.[i18next.language as Types.Languages]?.role ===
														Types.CustomerRoleTypesList.teacher && (
														<>
															<Grid item>
																<Link to={`/school/${school._id}`}>
																	<Button color="inherit" className={classes.navButton}>
																		Moje škola
																	</Button>
																</Link>
															</Grid>
															<Grid item>
																<Box sx={dividerStyle}></Box>
															</Grid>
														</>
													)}
												<>
													<Grid item>
														<Link to={'/profile'}>
															<Button color="inherit" className={classes.navButton}>
																{Helpers.getUsername(user)}
															</Button>
														</Link>
													</Grid>
													<Grid item>
														<Box sx={dividerStyle}></Box>
													</Grid>
													<Grid item>
														<authContext.Consumer>
															{({ logout }) => (
																<Button
																	color="inherit"
																	className={classes.navButton}
																	onClick={() => logout()}
																	id="btn-logout"
																>
																	Odhlásit
																</Button>
															)}
														</authContext.Consumer>
													</Grid>
												</>
											</Grid>
										)}
									</Grid>
								</Grid>
							</Grid>
							{bookActive && <BookViewOptions />}
						</Grid>
					</Box>
				</AppBar>
			)}
			<main className={classes.content}>{props.children}</main>
		</div>
	);
};

export default connect((state: Types.RootState) => ({
	user: state.user,
	bookActive: state.bookActive,
	schools: state.schools,
}))(NavBar);

export default [
	'Europa',
	'Interaktivna_mapa_Europy',
	'Interaktivna_mapa_Juznej_Afriky',
	'Interaktivna_mapa_Severnej_Afriky',
	'Interaktivna_mapa_Strednej_Afriky',
	'Juzna_Afrika',
	'Kraje_Slovenskej_republiky',
	'Slnecna_sustava',
	'Slunecni_soustava',
	'Stredna_Afrika',
	'Untitled Quiz3 (Web)',
	'bonus4',
	'bonus5',
	'bonus6',
	'chemicke_pokusy_cz',
	'chemicke_pokusy_sk',
	'ciselna_osa_zaba',
	'diesel_motor',
	'europa',
	'evropa_rusko',
	'fyzici',
	'fyzici_6_sr',
	'fyzici_7',
	'fyzici_7_sr',
	'fyzici_7_test',
	'fyzici_8_sr',
	'fyzici_8_test',
	'fyzici_9_sr',
	'fyzici_test',
	'generator_fyz_cr_2',
	'generator_fyz_sr_2',
	'generator_si',
	'generator_testu_cz',
	'generator_testu_sk',
	'ha_s14_cv4',
	'ha_s26_cv5',
	'ha_s2_cv1',
	'ha_s2_cv1 (Web)',
	'ha_s31_cv3',
	'ha_s39_cv7',
	'ha_s46_cv6',
	'ha_s9_cv2',
	'hch8_1_sk',
	'hch8_2_sk',
	'hch8_3_sk',
	'hch8_3b_sk',
	'hch8_3c_sk',
	'hch8_4_sk',
	'hch8_kviz_sr',
	'hch8_opakovani_cr',
	'hch9_1_sk',
	'hch9_2_sk',
	'hch9_3_sk',
	'hch9_3b_sk',
	'hch9_4_sk',
	'hch9_5_sk',
	'hch9_6_sk',
	'hch9_kviz_sr',
	'hch9_opakovani_cr',
	'hf6_fyzikove_2_cr',
	'hf6_kviz_sr_4',
	'hf6_kviz_telesa_v_kvapalinach_sk',
	'hf6_kviz_telesa_v_plynoch_sk',
	'hf6_kviz_vlastnosti_kvapaliny_plyny_sk_4',
	'hf6_kviz_vlastnosti_telies_sk',
	'hf6_opakovani_cr',
	'hf7_fyzikove_cr',
	'hf7_kviz_kvapalina_plyn_sk',
	'hf7_kviz_opakovanie_6_sk',
	'hf7_kviz_plyn_kvapalina_sk',
	'hf7_kviz_sr',
	'hf7_kviz_teplo_energia_sk',
	'hf7_kviz_teplota_cas_sk',
	'hf7_kviz_topenie_tuhnutie_sk',
	'hf7_kviz_vymena_tepla_sk',
	'hf7_opakovani_cr',
	'hf8_fyzikove_cr',
	'hf8_kviz_energia_v_prirode_sk',
	'hf8_kviz_mechanicka_praca_8_sk',
	'hf8_kviz_odraz_a_lom_svetla_sk',
	'hf8_kviz_opakovanie_uciva_7_sk',
	'hf8_kviz_opakovanie_uciva_7_sk_1',
	'hf8_kviz_pohyb_telesa_sk',
	'hf8_kviz_skumanie_sily_sk',
	'hf8_kviz_skumanie_vlastnosti_svetla_sk',
	'hf8_kviz_sr_1',
	'hf8_opakovani_cr',
	'hf9_fyzikove_cr',
	'hf9_kviz_elektricka_energia_a_vyuzitie_sk',
	'hf9_kviz_elektricky_obvod_sk',
	'hf9_kviz_elektrizovanie_telies_sk',
	'hf9_kviz_magnety_sk',
	'hf9_kviz_ohmov_zakon_sk',
	'hf9_kviz_opakovanie_8_sk',
	'hf9_kviz_sr',
	'hf9_kviz_vedenie_prudu_v_tekutinach_sk',
	'hf9_kviz_veliciny_el_obvod_sk',
	'hf9_opakovani_cr',
	'hg7_kviz_afrika_obyvatelstvo',
	'hg7_kviz_afrika_prirodne pomery',
	'hg7_kviz_afrika_prirodne_pomery',
	'hg7_kviz_azia_obyvatelstvo',
	'hg7_kviz_azia_prirodne pomery',
	'hg7_kviz_svet',
	'hg8_kviz_europa_jvv_sk',
	'hg8_kviz_europa_obyvatelstvo_sk',
	'hg8_kviz_europa_priroda_sk',
	'hg8_kviz_europa_sj_sk',
	'hg8_kviz_europa_sz_sk',
	'hg9_kviz_sr_obyvatelstvo_sk',
	'hg9_kviz_sr_priroda_sk',
	'hg9_kviz_sr_regiony_sk',
	'hg9_kviz_sr_zivot_sk',
	'hg9_zivot_sk',
	'hm2_rybar_cr',
	'hp1_s12_1',
	'hp1_s13_1',
	'hp1_s15_1',
	'hp1_s15_2',
	'hp1_s17_1',
	'hp1_s20_1',
	'hp1_s23_1',
	'hp1_s28_1',
	'hp1_s30_1',
	'hp1_s30_2',
	'hp1_s34_1',
	'hp1_s37_1',
	'hp1_s38_1',
	'hp1_s39_1',
	'hp1_s41_1',
	'hp1_s42_1',
	'hp1_s49_1',
	'hp1_s4_1',
	'hp1_s51_1',
	'hp1_s52_1',
	'hp1_s53_1',
	'hp1_s57_1',
	'hp1_s66_1',
	'hp1_s69_1',
	'hp1_s70_1',
	'hp1_s73_1',
	'hp1_s75_1',
	'hp1_s77_1',
	'hp1_s78_1',
	'hp1_s79_1',
	'hp1_s7_1',
	'hp1_s8_1',
	'hp1_s8_2',
	'hp2_basnicky_lentak',
	'hp2_basnicky_rastlinky',
	'hp2_basnicky_zelenina',
	'hp2_basnicky_zvierata',
	'hp2_hadanka_mravce',
	'hp2_hadanka_vcela',
	'hp2_hadanka_zvierata_oprava',
	'hp2_hadanky_cas',
	'hp2_hadanky_kvety',
	'hp2_s17_1',
	'hp2_s26_1',
	'hp2_s27_1',
	'hp2_s27_2',
	'hp2_s27_3',
	'hp2_s27_4',
	'hp2_s2_1',
	'hp2_s36_1',
	'hp2_s38_1',
	'hp2_s44_1',
	'hp2_s44_2',
	'hp2_s46_1',
	'hp2_s47_1',
	'hp2_s48_1',
	'hp2_s4_1',
	'hp2_s56_1',
	'hp2_s57_1',
	'hp2_s59_1',
	'hp2_s61_1',
	'hp2_s61_2',
	'hp2_s63_1',
	'hp2_s72_1',
	'hp2_s74_1',
	'hp2_s75_1',
	'hp2_s77_1',
	'hp2_s7_1',
	'hp2_s8_1',
	'hp3_basnicka_byliny_cz',
	'hp3_basnicka_huby_cz',
	'hp3_basnicka_ryba_cz',
	'hp3_basnicka_strom_cz',
	'hp3_basnicka_vcela_cz',
	'hp3_basnicka_vitr_cz',
	'hp3_basnicka_zajace_cz',
	'hp3_basnicka_zvierata_sad_cz',
	'hp3_hadanka_ostruziny_cz',
	'hp3_hadanka_sad_cz',
	'hp3_hadanka_zelenina_cz',
	'hv4_s11_libuse',
	'hv4_s15_dvojice_hory',
	'hv4_s16_anezka',
	'hv4_s16_reka_labe',
	'hv4_s17_cesko_nej',
	'hv4_s17_lucemburkove',
	'hv4_s17_manzelky',
	'hv4_s18_karel',
	'hv4_s20_chmel',
	'hv4_s20_zvirata_serazovani',
	'hv4_s21_kaolin',
	'hv4_s21_surovina_vyrobek',
	'hv4_s23_hus',
	'hv4_s25_jiri',
	'hv4_s26_panovnici',
	'hv4_s26_zajimavosti',
	'hv4_s28_mesta',
	'hv4_s4_dvojice',
	'hv4_s6_samo',
	'hv4_s8_bivoj',
	'hv4_s9_svatopluk',
	'hv_s16_reka_jizera',
	'hv_s16_reka_vltava',
	'juzna_afrika',
	'kolumbus2',
	'kraje_sr',
	'mapa',
	'mapka2',
	'mapy_dynamicke_cz',
	'mapy_dynamicke_sk',
	'mapy_geokviz',
	'mapy_geokviz_cz',
	'mapy_geokviz_sk_2',
	'mapy_kviz_cz',
	'mapy_kviz_eng',
	'mapy_kviz_sk',
	'matematika_pocitani',
	'modul_pexeso_eu_cz',
	'modul_pexeso_mat',
	'modul_pexeso_mat_2',
	'modul_pexeso_mat_3',
	'moreplavci',
	'moreplavci_sk',
	'periodicka_tabulka',
	'periodicka_tabulka_cz',
	'periodicka_tabulka_sk',
	'pexeso_ANJ3_mix',
	'pexeso_ANJ3_mix_2',
	'pexeso_ANJ4_mix',
	'pexeso_ANJ4_mix2',
	'pexeso_CES4_revision 1',
	'pexeso_CES4_revision 2',
	'pexeso_CES4_revision 3',
	'pexeso_CES4_revision 4',
	'pexeso_CIT_sk_homonyma',
	'pexeso_HC2_2_protiklady',
	'pexeso_HC2_2_slovní_druhy_1',
	'pexeso_HC2_2_slovní_druhy_2',
	'pexeso_HC2_2_slovní_druhy_3',
	'pexeso_HC2_abeceda',
	'pexeso_HC2_dvojice_new',
	'pexeso_HHV1_obliceje',
	'pexeso_HHV2_hud_nastroje',
	'pexeso_HPR3',
	'pexeso_MK1_family',
	'pexeso_MK1_festivals',
	'pexeso_MK2_colours',
	'pexeso_MK2_words',
	'pexeso_MM1_1_ovocie_a_zelenina',
	'pexeso_MM1_1_zvieratka',
	'pexeso_abeceda_cz',
	'pexeso_abeceda_pis_cr',
	'pexeso_aj3_U1_2',
	'pexeso_aj3_U3_4',
	'pexeso_aj3_U5_6',
	'pexeso_aj3_U7_8',
	'pexeso_aj3_sviatky',
	'pexeso_eu',
	'pexeso_eu_sk',
	'pexeso_hrady',
	'pexeso_prvouka_2_sr',
	'pexeso_sjl_2_sr',
	'pexeso_slab_1_pis_sr',
	'pexeso_slabikar_1_sr',
	'pexeso_slabikar_2_sr',
	'pismeno_M',
	'pismeno_m',
	'porovnavani_1',
	'prevody_jednotiek_SI',
	'priklady_vypocet_1',
	'písmeno M',
	'quiz',
	'riskuj_z_1',
	'riskuj_z_2',
	'severna_afrika',
	'slavni_moreplavci_sk',
	'sr_unesco',
	'sr_unesco_mapa_test',
	'stredna_afrika',
	'taktik_biliard',
	'taktik_databanka',
	'taktik_databanka_cz',
	'taktik_hodiny',
	'taktik_hodiny_sk',
	'taktik_manual',
	'taktik_manual_old',
	'taktik_payment_modul',
	'taktik_pismenka_cz',
	'taktik_pismenka_sk',
	'taktik_puzzle',
	'taktik_puzzle_eng',
	'taktik_puzzle_old',
	'taktik_puzzle_sr',
	'taktik_riskuj',
	'taktik_riskuj_6_1',
	'taktik_riskuj_6_2',
	'taktik_riskuj_cz',
	'taktik_riskuj_eng',
	'taktik_rybar_jazyky',
	'taktik_rybar_matematika',
	'taktik_sort',
	'taktik_sort_eng',
	'taktik_sort_old',
	'taktik_sort_sk1',
	'taktik_sort_sk_old',
	'taktik_text_puzzle',
	'taktik_text_puzzle_sk',
	'taktik_tucnak',
	'taktik_uz_vim',
	'taktik_uz_vim_EN',
	'taktik_uz_vim_sk',
	'taktik_vetny_rozbor',
	'taktik_vetny_rozbor_sk',
	'taktik_vyber_pismenko',
	'taktik_vyber_pismenko_sk',
	'test_modul_2',
	'test_modul_3',
	'test_payment',
	'test_pre',
	'tisk_studentu',
	'v2-Billiard-matematika',
	'v2-Databanka',
	'v2-Fyzika-SI',
	'v2-Geokviz',
	'v2-Hodiny',
	'v2-Linearni_rovnice',
	'v2-Manual',
	'v2-Mapy',
	'v2-Mapy-kviz',
	'v2-Obchod',
	'v2-Pexeso',
	'v2-Porovnavani',
	'v2-Puzzle',
	'v2-Riskuj',
	'v2-Rybar-jazyky',
	'v2-Rybar-matematika',
	'v2-Sada-modulu',
	'v2-Skupina-modulu',
	'v2-Testy-a-zkouseni',
	'v2-Textove-puzzle',
	'v2-Tisk-studentu',
	'v2-Trideni-obrazku',
	'v2-Tucnak-matematika',
	'v2-Uz-vim',
	'v2-Vetny-rozbor',
	'v2-Vyber-pismenko',
	'v2-Zaba-matematika',
	'v3-Mapy-kviz',
	'v3-Riskuj-nepouzite',
	'v3-Sada-modulu',
	'v3-Textove-puzzle',
	'zaba',
];

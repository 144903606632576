import Slider from '@mui/material/Slider';
import Box from '@mui/material/Box';
import Types from '@taktik/common/types';
import { connect, useDispatch } from 'react-redux';
import { SyntheticEvent, useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import Reducers from '@taktik/common/reducers';
import Grid from '@mui/material/Grid';
import { pageFlipDelay } from '..';

interface BookSliderProps {
	bookPages: Types.BookPageDocument[];
	bookActive: Types.BookActiveProps;
}

const getLabelValue = (
	orientation: Types.BookOrientation,
	visiblePages: number[],
	pagesCount: number
) => {
	return orientation === 'horizontal'
		? visiblePages[0] === 0
			? 'Úvod'
			: visiblePages[0] < 0 || visiblePages[0] === pagesCount - 2
			? `Obálka`
			: `${visiblePages[0]}, ${visiblePages[1]}`
		: visiblePages[0] === -1 || visiblePages[0] === pagesCount - 2
		? `Obálka`
		: visiblePages[0] === 0
		? `Úvod`
		: `${visiblePages[0]}`;
};

const BookSlider = (props: BookSliderProps) => {
	const dispatch = useDispatch();
	const bookActive = props.bookActive;
	const bookPages = props.bookPages;
	const [value, setValue] = useState(bookActive?.visiblePages[0] || 1);
	const [prevValue, setPrevValue] = useState(-1);
	const [labelValue, setLabelValue] = useState(
		getLabelValue(
			bookActive?.pageDimensions.orientation || 'horizontal',
			bookActive?.visiblePages || [-2, -1],
			bookPages.length
		)
	);

	const handleChange = (event: Event | SyntheticEvent, newValue: number | number[]) => {
		setValue(newValue as number);
	};

	const flipPage = (event: Event | SyntheticEvent, newValue: number | number[]) => {
		if (prevValue !== newValue) {
			const val = newValue as number;
			setPrevValue(val);
			if (bookActive) {
				if (bookActive.pageDimensions.orientation === 'horizontal') {
					dispatch(
						Reducers.interaktiv.bookActive.actions.set({
							...bookActive,
							visiblePages: [val, val + 1],
							controls: false,
						})
					);
					setTimeout(() => {
						dispatch(
							Reducers.interaktiv.bookActive.actions.set({
								...bookActive,
								visiblePages: [val, val + 1],
								controls: true,
							})
						);
					}, pageFlipDelay);
				}
				if (bookActive?.pageDimensions.orientation === 'vertical') {
					dispatch(
						Reducers.interaktiv.bookActive.actions.set({
							...bookActive,
							visiblePages: [val],
							controls: false,
						})
					);
					setTimeout(() => {
						dispatch(
							Reducers.interaktiv.bookActive.actions.set({
								...bookActive,
								visiblePages: [val],
								controls: true,
							})
						);
					}, pageFlipDelay);
				}
			}
		}
	};

	useEffect(() => {
		if (bookActive?.visiblePages) {
			setValue(bookActive.visiblePages[0]);
		}
	}, [bookActive]);

	useEffect(() => {
		if (bookActive) {
			setLabelValue(
				getLabelValue(bookActive.pageDimensions.orientation, [value, value + 1], bookPages.length)
			);
		}
	}, [bookActive, value, bookPages]);

	return (
		<Grid container alignItems="center" spacing={1}>
			<Grid item position="relative">
				<Box
					sx={{
						position: 'absolute',
						right: 2,
						top: -4,
						whiteSpace: 'nowrap',
					}}
				>
					<Typography color="secondary" fontWeight={700}>
						{labelValue}
					</Typography>
				</Box>
			</Grid>
			<Grid item>
				{bookActive && (
					<Box mx={1} width={300}>
						<Slider
							aria-label="Stránka"
							value={value}
							onChange={handleChange}
							onChangeCommitted={flipPage}
							step={bookActive.pageDimensions.orientation === 'horizontal' ? 2 : 1}
							min={bookActive.pageDimensions.orientation === 'horizontal' ? -2 : -1}
							max={
								bookActive.pageDimensions.orientation === 'horizontal'
									? bookPages.length - 2
									: bookPages.length - 2
							}
							color="secondary"
							style={{
								display: 'block',
							}}
							disabled={!bookActive.controls}
						/>
					</Box>
				)}
			</Grid>
		</Grid>
	);
};

export default connect((state: Types.RootState) => ({
	bookPages: state.bookPages,
	bookActive: state.bookActive,
}))(BookSlider);

import Types from '@taktik/common/types';
import { useEffect, useState } from 'react';

interface BookVerticalProps {
	bookPages: Types.BookPageDocument[];
	visiblePages: number[];
}

const BookVertical = (props: BookVerticalProps) => {
	const visiblePages = props.visiblePages;
	const visiblePage = visiblePages[0] !== null ? visiblePages[0] : -1;
	const [verticalPages, setVerticalPages] = useState<Types.BookPageDocument[]>([]);
	const [frontPage, setFrontPage] = useState<Types.BookPageDocument>();
	const [backPage, setBackPage] = useState<Types.BookPageDocument>();
	const [prevVisiblePage, setPrevVisiblePage] = useState(visiblePage);
	const [rotation, setRotation] = useState(0);

	useEffect(() => {
		const pages = props.bookPages;
		setVerticalPages(pages);
	}, [props.bookPages]);

	useEffect(() => {
		if (verticalPages.length > 0) {
			setTimeout(() => {
				setRotation(rotation => {
					const currentSide = rotation === 0 ? 'back' : (rotation / 180) % 2 ? 'front' : 'back';
					const isEven = visiblePage % 2 === 0;

					if (isEven) {
						const newFrontPage = verticalPages.find(p => p.page === visiblePage);
						const newBackPage = verticalPages.find(p => p.page === visiblePage + 1);
						if (currentSide === 'front') {
							setFrontPage(newBackPage);
							setBackPage(newFrontPage);
						} else {
							setFrontPage(newFrontPage);
							setBackPage(newBackPage);
						}
					} else {
						const newFrontPage = verticalPages.find(p => p.page === visiblePage + 1);
						const newBackPage = verticalPages.find(p => p.page === visiblePage);
						if (currentSide === 'back') {
							setFrontPage(newBackPage);
							setBackPage(newFrontPage);
						} else {
							setFrontPage(newFrontPage);
							setBackPage(newBackPage);
						}
					}

					return rotation;
				});
			}, 250);
		}
	}, [visiblePages, verticalPages]);

	useEffect(() => {
		setPrevVisiblePage(prevVisiblePage => {
			setRotation(rotation => {
				const currentSide = rotation === 0 ? 'back' : (rotation / 180) % 2 ? 'front' : 'back';
				if (currentSide === 'front') {
					if (visiblePage > prevVisiblePage) {
						return rotation - 180;
					} else {
						return rotation + 180;
					}
				} else if (currentSide === 'back') {
					if (visiblePage > prevVisiblePage) {
						return rotation - 180;
					} else {
						return rotation + 180;
					}
				}
				return rotation;
			});
			return visiblePage;
		});
	}, [visiblePage]);

	return (
		<div
			className="flip"
			style={{
				transformOrigin: 'center',
				transform: `rotateY(${rotation}deg)`,
			}}
		>
			{frontPage && (
				<div className="front">
					<img
						src={`${process.env.REACT_APP_FILES}${frontPage.image}`}
						style={{ width: '100%', height: '100%', backgroundColor: '#fff' }}
					/>
				</div>
			)}
			{backPage && (
				<div className="back">
					<img
						src={`${process.env.REACT_APP_FILES}${backPage.image}`}
						style={{ width: '100%', height: '100%', backgroundColor: '#fff' }}
					/>
				</div>
			)}
		</div>
	);
};

export default BookVertical;

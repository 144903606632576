import { lazy, Suspense, useState } from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import ThemeProvider from '@mui/material/styles/ThemeProvider';
import { I18nextProvider } from 'react-i18next';
import i18next from 'i18next';
import CssBaseline from '@mui/material/CssBaseline';
import './assets/fonts.css';
import thunk from 'redux-thunk';
import { configureStore } from '@reduxjs/toolkit';
import { Provider } from 'react-redux';
import makeStyles from '@mui/styles/makeStyles';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import Loader from './components/Loader';
import reducer from './reducers';

import theme from './theme';

import { PusherProvider } from '@harelpls/use-pusher';
import { SnackbarProvider } from 'notistack';

import TranslationLoader from '@taktik/components/TranslationLoader';
import ErrorBoundary from '@taktik/components/ErrorBoundary';
import NavBar from './components/NavBar';
import Types from '@taktik/common/types';
import { authContext } from '@taktik/common/auth';
import Auth from '@taktik/common/auth';
import axios from 'axios';

i18next.init({
	interpolation: { escapeValue: false },
	lng: 'cs',
	resources: {},
});

const useStyle = makeStyles({
	snackbarRoot: {
		marginLeft: 15,
		marginBottom: 96,
	},
});

// Pusher config
const pusherConfig = {
	clientKey: '0d7a53bab3e525777c45',
	cluster: 'eu',
};

axios.defaults.params = {};
axios.defaults.params['project'] = 'interaktiv';
axios.defaults.params['language'] = i18next.language;

const HomePage = lazy(() => import('./pages/homepage'));
const MigratePage = lazy(() => import('./pages/migrate'));
const ModulesPage = lazy(() => import('./pages/modules'));
const BooksPage = lazy(() => import('./pages/books'));
const BookDetailPage = lazy(() => import('./pages/book'));
const SchoolsPage = lazy(() => import('./pages/schools'));
const UsersPage = lazy(() => import('./pages/users'));
const FeedbacksPage = lazy(() => import('./pages/feedbacks'));
const VouchersPage = lazy(() => import('./pages/vouchers'));
const VoucherPage = lazy(() => import('./pages/voucher'));
const ResetPasswordPage = lazy(() => import('./pages/resetPassword'));
const ProfilePage = lazy(() => import('./pages/profile'));
const RegisterStudentPage = lazy(() => import('./pages/registerStudent'));

const store = configureStore({
	reducer,
	middleware: getDefaultMiddleware =>
		getDefaultMiddleware({
			thunk: {
				extraArgument: thunk,
			},
		}),
});

const App = () => {
	const classes = useStyle();
	const [loading, setLoading] = useState(true);

	return (
		<SnackbarProvider
			maxSnack={5}
			dense
			classes={{
				containerRoot: classes.snackbarRoot,
			}}
		>
			<Auth>
				<authContext.Consumer>
					{({ authenticated, token, silentAuth, user }) => {
						if (!authenticated && token) {
							silentAuth();
							return;
						}

						return loading ? (
							<>
								<TranslationLoader
									language={i18next.language as Types.Languages}
									onLoad={() => setLoading(false)}
								/>
								<Loader />
							</>
						) : (
							<Router>
								<Routes>
									<Route
										path="/"
										element={
											<Suspense fallback={<Loader />}>
												<NavBar hide={user.role === Types.RoleTypesList.unauthorized}>
													<HomePage />
												</NavBar>
											</Suspense>
										}
									/>
									<Route
										path="/migrate"
										element={
											<Suspense fallback={<Loader />}>
												<NavBar>
													<MigratePage />
												</NavBar>
											</Suspense>
										}
									/>
									<Route
										path="/seznam-ucebnic"
										element={
											<Suspense fallback={<Loader />}>
												<NavBar>
													<BooksPage />
												</NavBar>
											</Suspense>
										}
									/>
									<Route
										path="/ucebnice/:bookId"
										element={
											<Suspense fallback={<Loader />}>
												<NavBar>
													<BookDetailPage />
												</NavBar>
											</Suspense>
										}
									/>
									<Route
										path="/schools"
										element={
											<Suspense fallback={<Loader />}>
												<NavBar>
													<SchoolsPage />
												</NavBar>
											</Suspense>
										}
									/>
									<Route
										path="/school/:schoolId"
										element={
											<Suspense fallback={<Loader />}>
												<NavBar>
													<SchoolsPage />
												</NavBar>
											</Suspense>
										}
									/>
									<Route
										path="/users"
										element={
											<Suspense fallback={<Loader />}>
												<NavBar>
													<UsersPage />
												</NavBar>
											</Suspense>
										}
									/>
									<Route
										path="/user/:userId"
										element={
											<Suspense fallback={<Loader />}>
												<NavBar>
													<UsersPage />
												</NavBar>
											</Suspense>
										}
									/>
									<Route
										path="/feedback"
										element={
											<Suspense fallback={<Loader />}>
												<NavBar>
													<FeedbacksPage />
												</NavBar>
											</Suspense>
										}
									/>
									<Route
										path="/feedback/:feedbackId"
										element={
											<Suspense fallback={<Loader />}>
												<NavBar>
													<FeedbacksPage />
												</NavBar>
											</Suspense>
										}
									/>
									<Route
										path="/vouchers"
										element={
											<Suspense fallback={<Loader />}>
												<NavBar>
													<VouchersPage />
												</NavBar>
											</Suspense>
										}
									/>
									<Route
										path="/voucher/:voucherId"
										element={
											<Suspense fallback={<Loader />}>
												<NavBar>
													<VoucherPage />
												</NavBar>
											</Suspense>
										}
									/>
									<Route
										path="/modules"
										element={
											<Suspense fallback={<Loader />}>
												<NavBar>
													<ModulesPage />
												</NavBar>
											</Suspense>
										}
									/>
									<Route
										path="/reset-password/:userId/:hash"
										element={
											<Suspense fallback={<Loader />}>
												<ResetPasswordPage />
											</Suspense>
										}
									/>
									<Route
										path="/profile"
										element={
											<NavBar>
												<Suspense fallback={<Loader />}>
													<ProfilePage />
												</Suspense>
											</NavBar>
										}
									/>
									<Route
										path="/register/:classroomId"
										element={
											<NavBar>
												<Suspense fallback={<Loader />}>
													<RegisterStudentPage />
												</Suspense>
											</NavBar>
										}
									/>
								</Routes>
							</Router>
						);
					}}
				</authContext.Consumer>
			</Auth>
		</SnackbarProvider>
	);
};

const container = document.getElementById('root');
if (container) {
	const root = createRoot(container);
	root.render(
		<Provider store={store}>
			<ThemeProvider theme={theme}>
				<PusherProvider {...pusherConfig}>
					<I18nextProvider i18n={i18next}>
						<LocalizationProvider dateAdapter={AdapterMoment}>
							<CssBaseline />
							<ErrorBoundary>
								<App />
							</ErrorBoundary>
						</LocalizationProvider>
					</I18nextProvider>
				</PusherProvider>
			</ThemeProvider>
		</Provider>
	);
}

import { useEffect, useState } from 'react';
import * as React from 'react';
import ErrorIcon from '@mui/icons-material/Error';
import axios from 'axios';
import {
	UploadFolders,
	UploadFileTypes,
	TaktikFile,
	UserSession,
	ImageSizes,
	UploadFile,
	UploadResponse,
	RootState,
} from '@taktik/common/types';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import DeleteForever from '@mui/icons-material/DeleteForever';
import CloudUpload from '@mui/icons-material/CloudUpload';
import Publish from '@mui/icons-material/Publish';
import makeStyles from '@mui/styles/makeStyles';
import Image from '../Image';
import T from '../T';
import { connect } from 'react-redux';

const useStyles = makeStyles({
	fileContainer: {
		position: 'relative',
		border: `1px solid #1976d2`,
		borderRadius: 5,
		padding: 8,
	},
	fileActions: {
		position: 'absolute',
		top: 12,
		right: 16,
		background: 'rgba(255,255,255,0.5)',
	},
});

const uploadFile = async (
	file: File,
	folder: UploadFolders,
	path: string,
	filename: string,
	onProgress: (progress: number) => void,
	token: string
) => {
	const data = new FormData();
	data.append('folder', folder);
	data.append('file', file);
	data.append('path', path);
	data.append('filename', filename);

	const route = file.type === 'image/jpeg' || file.type === 'image/png' ? 'image' : 'file';

	return axios.post(`${process.env.REACT_APP_API_CENTRAL}/upload/${route}`, data, {
		onUploadProgress: ProgressEvent => {
			onProgress((ProgressEvent.loaded / ProgressEvent.total) * 100);
		},
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
};

export interface UploadProps {
	fileType: UploadFileTypes;
	folder: UploadFolders;
	path: string;
	filename: string;
	onFile?: () => void;
	onProgress?: (progress: number) => void;
	onDelete: () => void;
	onUpload: (file: TaktikFile) => void;
	user: UserSession;
	error?: boolean;
	upload?: TaktikFile;
	imageSize?: ImageSizes;
	iconOnly?: boolean;
	cover?: boolean;
}

const Upload = (props: UploadProps) => {
	const classes = useStyles();
	const [data, setData] = useState<UploadFile>({});
	const [uploadProgress, setUploadProgress] = useState<number>(0);
	const filesPath = process.env.REACT_APP_FILES;
	const fileName =
		props.fileType === 'audio/mpeg,audio/wav,audio/ogg' ||
		props.fileType === 'audio/mpeg' ||
		props.fileType === 'audio/ogg' ||
		props.fileType === 'audio/wav' ? (
			<T id="types.uploadFile.audio" />
		) : props.fileType === 'image/jpeg,image/png' ||
		  props.fileType === 'image/jpeg' ||
		  props.fileType === 'image/png' ? (
			<T id="types.uploadFile.image" />
		) : (
			<T id="types.uploadFile.file" />
		);

	const onChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
		if (props.upload) {
			await removeFileRequest();
		}

		const file = event.target.files?.[0];
		if (file) {
			setData({ file });

			if (props.onFile) {
				props.onFile();
			}

			const reader = new FileReader();
			reader.onload = async () => {
				if (reader.result && typeof reader.result === 'string') {
					setData({
						file: file,
						base64: reader.result,
					});
				}
			};

			reader.readAsDataURL(file);

			uploadFile(file, props.folder, props.path, props.filename, setUploadProgress, props.user.token)
				.then((res: UploadResponse) => {
					props.onUpload(res.data);
				})
				.catch(err => console.log(err));
		}
	};

	const removeFileRequest = () => {
		return axios.delete(`${process.env.REACT_APP_API_CENTRAL}/upload/remove`, {
			data: {
				path: props.upload.path,
			},
			headers: {
				Authorization: `Bearer ${props.user.token}`,
			},
		});
	};

	const removeUpload = () => {
		removeFileRequest().then(() => {
			setData({
				file: undefined,
				base64: undefined,
				path: undefined,
			});
			props.onDelete();
		});
	};

	useEffect(() => {
		setData({});
	}, [props.upload]);

	if (props.upload?.path) {
		return (
			<>
				{(props.upload.type === 'image/jpeg' || props.upload.type === 'image/png') && (
					<div className={!props.cover ? classes.fileContainer : undefined}>
						<Image
							path={props.upload.path}
							size={props.imageSize}
							style={props.cover ? { width: '100%', height: '100%' } : undefined}
						/>
						<div className={classes.fileActions}>
							<IconButton size="small" color="default" aria-label="remove" onClick={removeUpload}>
								<DeleteForever />
							</IconButton>
							<IconButton size="small" color="default" aria-label="upload" component="label">
								<CloudUpload />
								<input
									type="file"
									style={{ display: 'none' }}
									onChange={event => onChange(event)}
									accept={props.fileType}
								/>
							</IconButton>
						</div>
					</div>
				)}
				{(props.upload.type === 'audio/mpeg' ||
					props.upload.type === 'audio/wav' ||
					props.upload.type === 'audio/ogg') && (
					<div className={classes.fileContainer}>
						<audio controls style={{ width: 300, height: 40 }}>
							<source src={filesPath + props.upload.path} />
						</audio>
						<div className={classes.fileActions}>
							<IconButton size="small" color="default" aria-label="remove" onClick={removeUpload}>
								<DeleteForever />
							</IconButton>
							<IconButton size="small" color="default" aria-label="upload" component="label">
								<CloudUpload />
								<input
									type="file"
									style={{ display: 'none' }}
									onChange={event => onChange(event)}
									accept={props.fileType}
								/>
							</IconButton>
						</div>
					</div>
				)}
			</>
		);
	}

	return (
		<Button
			variant="outlined"
			color="primary"
			component="label"
			startIcon={<Publish />}
			endIcon={props.error && <ErrorIcon color="error" />}
			size="small"
			className={`upload-${props.folder}-${props.filename}`}
		>
			{!props.iconOnly && (
				<>
					<T id="types.uploadFile.upload" /> {fileName}
				</>
			)}
			<input
				type="file"
				style={{ display: 'none' }}
				onChange={event => onChange(event)}
				accept={props.fileType}
			/>
		</Button>
	);
};

export default connect((state: RootState) => ({
	user: state.user,
}))(Upload);

import Types, { BonusTypes } from '@taktik/common/types';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { connect, useDispatch } from 'react-redux';
import reducers from '@taktik/common/reducers';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import modules from '../../../config/modules';
import { useEffect, useRef, useState } from 'react';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Upload from '@taktik/components/Upload';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import ConfirmDialog from '@taktik/components/ConfirmDialog';
import { Editor } from 'react-draft-wysiwyg';
import { ContentState, EditorState } from 'draft-js';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import htmlToDraft from 'html-to-draftjs';
import draftToHtml from 'draftjs-to-html';
import { SketchPicker } from 'react-color';
import Popper from '@mui/material/Popper';
import Grow from '@mui/material/Grow';
import ClickAwayListener from '@mui/material/ClickAwayListener';

interface BonusDialogProps {
	user: Types.UserSession;
	open: boolean;
	page: Types.BookPageDocument;
	bonus: Types.BonusDocument;
	onClose: () => void;
	bookActive: Types.BookActiveProps;
}

const BonusDialog = (props: BonusDialogProps) => {
	const page = props.page;
	const [bonus, setBonus] = useState(props.bonus);
	const [changed, setChanged] = useState(false);
	const [loading, setLoading] = useState(false);
	const [remove, setRemove] = useState(false);
	const [editorState, setEditorState] = useState<EditorState>();
	const colorAnchorRef = useRef<HTMLButtonElement>(null);
	const [colorOpen, setColorOpen] = useState(false);
	const bookActive = props.bookActive;
	const bonusType = bonus.type;
	const dispatch = useDispatch();

	const save = (newBonus?: Types.BonusDocument) => {
		if (newBonus) {
			setBonus(newBonus);
		}
		setLoading(true);
		dispatch(
			reducers.interaktiv.bookPages.actions.update(
				'update/bonus',
				{
					pageId: page._id,
					data: {
						bonusId: bonus._id,
						value: newBonus || bonus,
					},
				},
				props.user.token
			)
		).then(() => {
			setLoading(false);
			props.onClose();
		});
	};

	const setBookActiveMode = (mode: Types.BookEditTransformModes) => {
		if (bookActive) {
			dispatch(
				reducers.interaktiv.bookActive.actions.set({
					...bookActive,
					editor: {
						...bookActive.editor,
						transform: {
							...bookActive.editor.transform,
							mode,
						},
					},
				})
			);
		}
	};

	useEffect(() => {
		if (bonusType === 'presentation') {
			const contentBlock = htmlToDraft(bonus.html);
			if (contentBlock) {
				const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
				const editorState = EditorState.createWithContent(contentState);
				setEditorState(editorState);
			}
		}
	}, [bonusType]);

	return (
		<>
			<Dialog open={props.open} onClose={() => props.onClose()} maxWidth="md" fullWidth>
				<DialogTitle
					style={{ cursor: 'move' }}
					id="draggable-dialog"
					onMouseOver={() => setBookActiveMode('areaDetail')}
					onMouseLeave={() => setBookActiveMode('workspace')}
				>
					<Grid container justifyContent="space-between" alignItems="center">
						<Grid item>Editace bonusu</Grid>
						<Grid item>
							<Button variant="outlined" color="error" onClick={() => setRemove(true)}>
								Smazat bonus
							</Button>
						</Grid>
					</Grid>
				</DialogTitle>
				<DialogContent
					onMouseOver={() => setBookActiveMode('areaDetail')}
					onMouseLeave={() => setBookActiveMode('workspace')}
				>
					<Grid container spacing={3}>
						<Grid item md={12}>
							<TextField
								label="Názov"
								value={bonus.title}
								onChange={event => {
									setBonus({
										...bonus,
										title: event.target.value,
									});
									setChanged(true);
								}}
								fullWidth
							/>
						</Grid>
						<Grid item md={12}>
							<FormControl fullWidth>
								<InputLabel color="primary" id="bonus-type">
									Typ
								</InputLabel>
								<Select
									label="Typ"
									labelId="bonus-type"
									value={bonus.type}
									onChange={event => {
										setBonus({
											...bonus,
											type: event.target.value as BonusTypes,
										});
										setChanged(true);
									}}
								>
									<MenuItem value={'link' as Types.BonusTypes}>Link</MenuItem>
									<MenuItem value={'video' as Types.BonusTypes}>Video</MenuItem>
									<MenuItem value={'presentation' as Types.BonusTypes}>Prezentace</MenuItem>
									<MenuItem value={'module' as Types.BonusTypes}>Modul</MenuItem>
									<MenuItem value={'risk' as Types.BonusTypes}>Risk</MenuItem>
									<MenuItem value={'quiz' as Types.BonusTypes}>Quiz</MenuItem>
								</Select>
							</FormControl>
						</Grid>
						{bonus.type === 'module' && (
							<Grid item md={12}>
								<FormControl fullWidth>
									<InputLabel color="primary" id="bonus-type">
										Typ modulu
									</InputLabel>
									<Select
										label="Typ modulu"
										labelId="bonus-type"
										value={bonus.module}
										onChange={event => {
											setBonus({
												...bonus,
												module: event.target.value,
											});
											setChanged(true);
										}}
									>
										{modules.map(module => (
											<MenuItem key={`module-select-${module}`} value={module}>
												{module}
											</MenuItem>
										))}
									</Select>
								</FormControl>
							</Grid>
						)}
						{bonus.type === 'link' && (
							<Grid item md={12}>
								<TextField
									label="Url"
									value={bonus.url}
									onChange={event => {
										setBonus({
											...bonus,
											url: event.target.value as BonusTypes,
										});
										setChanged(true);
									}}
									fullWidth
								/>
							</Grid>
						)}
						{bonus.type !== 'presentation' && (
							<Grid item md={12}>
								<TextField
									label="Params"
									value={bonus.params}
									onChange={event => {
										setBonus({
											...bonus,
											params: event.target.value as BonusTypes,
										});
										setChanged(true);
									}}
									fullWidth
								/>
							</Grid>
						)}
						{bonus.type === 'presentation' && (
							<>
								<Grid item md={12}>
									<Paper variant="outlined">
										<Box p={2}>
											<Editor
												editorState={editorState}
												onEditorStateChange={setEditorState}
												onChange={contentState => {
													const html = draftToHtml(contentState);
													setBonus({
														...bonus,
														html,
													});
													setChanged(true);
												}}
											/>
										</Box>
									</Paper>
								</Grid>
								<Grid item md={12}>
									<Typography>Obrázky prezentace</Typography>
								</Grid>
								{bonus.images.map((image, key) => (
									<Grid item md={3}>
										<Upload
											upload={
												image
													? {
															path: image.src,
															type: 'image/jpeg',
													  }
													: undefined
											}
											folder="book"
											path={`${page.bookId}/${page._id}/bonus`}
											fileType="image/jpeg"
											filename={`${key}`}
											onDelete={() => {
												const newImages = [...bonus.images];
												newImages.splice(key, 1);
												const newBonus: Types.BonusDocument = {
													...bonus,
													images: newImages,
												};
												save(newBonus);
											}}
											onUpload={file => {
												const newImages = [...bonus.images];
												newImages[key] = { src: file.path, title: '' };
												const newBonus: Types.BonusDocument = {
													...bonus,
													images: newImages,
												};
												save(newBonus);
											}}
										/>
									</Grid>
								))}
								{bonus.images.length < 4 && (
									<Grid item md={3}>
										<Upload
											folder="book"
											path={`${page.bookId}/${page._id}/bonus`}
											fileType="image/jpeg"
											filename={`${bonus.images.length + 1}`}
											onDelete={() => {}}
											onUpload={file => {
												const newImages = [...bonus.images];
												newImages[bonus.images.length] = { src: file.path, title: '' };
												const newBonus: Types.BonusDocument = {
													...bonus,
													images: newImages,
												};
												save(newBonus);
											}}
										/>
									</Grid>
								)}
							</>
						)}
						<Grid item md={12}>
							<Typography>Barva</Typography>
							<Button
								variant="outlined"
								size="small"
								ref={colorAnchorRef}
								onClick={() => setColorOpen(prevOpen => !prevOpen)}
							>
								<span
									style={{
										width: 50,
										height: 50,
										background: `#${bonus.color}`,
									}}
								></span>
							</Button>
							<Popper
								sx={{
									zIndex: 1,
								}}
								open={colorOpen}
								anchorEl={colorAnchorRef.current}
								role={undefined}
								transition
								disablePortal
							>
								{({ TransitionProps, placement }) => (
									<Grow
										{...TransitionProps}
										style={{
											transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
										}}
									>
										<Paper>
											<ClickAwayListener onClickAway={() => setColorOpen(false)}>
												<SketchPicker
													color={`#${bonus.color}`}
													onChangeComplete={color => {
														setBonus({
															...bonus,
															color: color.hex.slice(1),
														});
														setChanged(true);
													}}
												/>
											</ClickAwayListener>
										</Paper>
									</Grow>
								)}
							</Popper>
						</Grid>
						<Grid item md={12}>
							<Typography>Náhled</Typography>
							<Upload
								upload={
									bonus.image
										? {
												path: bonus.image,
												type: 'image/jpeg',
										  }
										: undefined
								}
								folder="book"
								path={`${props.page.bookId}/${page._id}/bonuses`}
								filename={`${bonus.type}`}
								fileType="image/jpeg"
								onDelete={() => {
									const newBonus = {
										...bonus,
										image: '',
									};
									save(newBonus);
								}}
								onUpload={file => {
									const newBonus = {
										...bonus,
										image: file.path,
									};
									save(newBonus);
								}}
							/>
						</Grid>
						<Grid item md={12}>
							<FormControlLabel
								control={
									<Checkbox
										checked={bonus.position.isPoint}
										onChange={() => {
											setBonus({
												...bonus,
												position: {
													...bonus.position,
													isPoint: !bonus.position.isPoint,
												},
											});
											setChanged(true);
										}}
									/>
								}
								label="Interaktivní bod"
							/>
						</Grid>
						<Grid item md={bonus.position.isPoint ? 6 : 12}>
							<TextField
								label="Poloha zhora %"
								value={bonus.position.top}
								onChange={event => {
									setBonus({
										...bonus,
										position: {
											...bonus.position,
											top: parseInt(`${event.target.value}`),
										},
									});
									setChanged(true);
								}}
								fullWidth
							/>
						</Grid>
						{bonus.position.isPoint && (
							<Grid item md={6}>
								<TextField
									label="Poloha zleva %"
									value={bonus.position.left}
									onChange={event => {
										setBonus({
											...bonus,
											position: {
												...bonus.position,
												left: parseInt(`${event.target.value}`),
											},
										});
										setChanged(true);
									}}
									fullWidth
								/>
							</Grid>
						)}
						{changed && (
							<>
								<Grid item md={3}>
									<Button variant="outlined" fullWidth onClick={() => props.onClose()}>
										Zrušit
									</Button>
								</Grid>
								<Grid item md={9}>
									<Button variant="contained" disabled={loading} fullWidth onClick={() => save()}>
										Uložit změny
									</Button>
								</Grid>
							</>
						)}
					</Grid>
				</DialogContent>
			</Dialog>
			{remove && (
				<ConfirmDialog
					type="remove"
					title={`Smazat bonus "${bonus.title}"`}
					onClose={() => setRemove(false)}
					onSubmit={() => {
						dispatch(
							reducers.interaktiv.bookPages.actions.update(
								'remove/bonus',
								{
									pageId: page._id,
									data: {
										bonusId: bonus._id,
									},
								},
								props.user.token
							)
						).then(() => {
							props.onClose();
						});
					}}
				/>
			)}
		</>
	);
};

export default connect((state: Types.RootState) => ({
	user: state.user,
	bookActive: state.bookActive,
}))(BonusDialog);

import AppBar from '@mui/material/AppBar';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import Grid from '@mui/material/Grid';
import Types from '@taktik/common/types';
import CloseIcon from '@mui/icons-material/Close';
import { forwardRef, ReactElement, Ref } from 'react';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import Typography from '@mui/material/Typography';

interface BonusDialogProps {
	onClose: () => void;
	bonus: Types.Bonus;
}

const Transition = forwardRef(function Transition(
	props: TransitionProps & {
		children: ReactElement;
	},
	ref: Ref<unknown>
) {
	return <Slide direction="up" ref={ref} {...props} />;
});

const BonusDialog = (props: BonusDialogProps) => {
	const bonus = props.bonus;

	return (
		<Dialog open={true} onClose={() => props.onClose()} TransitionComponent={Transition} fullScreen>
			<AppBar sx={{ position: 'relative' }}>
				<Toolbar>
					<Grid container justifyContent="space-between" alignItems="center">
						<Grid item>
							<Typography variant="h6">{bonus.title}</Typography>
						</Grid>
						<Grid item>
							<IconButton edge="start" color="inherit" onClick={props.onClose} className="close-dialog">
								<CloseIcon />
							</IconButton>
						</Grid>
					</Grid>
				</Toolbar>
			</AppBar>
			<iframe
				src={
					bonus.type === 'module'
						? `https://interaktivita.taktik.sk/moduly/${bonus.module}/?${bonus.params.replaceAll(
								'*',
								'&'
						  )}`
						: bonus.type === 'video'
						? `https://www.youtube.com/embed/${bonus.url}?rel=0&showinfo=0&autoplay=1&modestbranding=1`
						: ''
				}
				width="100%"
				height="100%"
			></iframe>
		</Dialog>
	);
};

export default BonusDialog;

import { Types } from '@taktik/common';
import { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';

interface DrawingProps {
	bookActive: Types.BookActiveProps;
}

type DrawProps = {
	from: {
		x: number;
		y: number;
	};
	to: {
		x: number;
		y: number;
	};
};

const Drawing = (props: DrawingProps) => {
	const bookActive = props.bookActive;
	const visiblePages = bookActive?.visiblePages;
	const mode = bookActive?.drawing.mode;
	const canvasRef = useRef<HTMLCanvasElement>(null);
	const [context, setContext] = useState<CanvasRenderingContext2D>();
	const [mousedown, setMousedown] = useState(false);
	const [canvasPosition, setCanvasPosition] = useState({
		x: 0,
		y: 0,
	});
	const [position, setPosition] = useState<DrawProps>({
		from: {
			x: 0,
			y: 0,
		},
		to: {
			x: 0,
			y: 0,
		},
	});

	const draw = (context: CanvasRenderingContext2D, position: DrawProps) => {
		if (bookActive && context) {
			context.beginPath();
			context.moveTo(position.from.x, position.from.y);
			context.lineTo(position.to.x, position.to.y);
			context.strokeStyle = bookActive.drawing.color;
			if (mode === 'eraser') {
				context.lineWidth = 40;
			} else {
				context.lineWidth = bookActive.drawing.size;
			}
			context.lineCap = 'round';
			context.stroke();
			context.closePath();
		}
	};

	const updateCanvasPosition = () => {
		const canvas = canvasRef.current;
		if (canvas) {
			const rect = canvas.getBoundingClientRect();
			const newCanvasPosition = {
				x: rect.left,
				y: rect.top,
			};
			setCanvasPosition(newCanvasPosition);
		}
	};

	useEffect(() => {
		if (context) {
			if (mode === 'eraser') {
				context.globalCompositeOperation = 'destination-out';
			} else if (mode === 'pencil') {
				context.globalCompositeOperation = 'source-over';
			}
		}
	}, [mode]);

	useEffect(() => {
		const onMousedown = () => {
			setMousedown(true);
		};
		const onMouseup = () => {
			setMousedown(false);
			setPosition({
				from: {
					x: 0,
					y: 0,
				},
				to: {
					x: 0,
					y: 0,
				},
			});
		};
		const onMousemove = (event: MouseEvent) => {
			setMousedown(mousedown => {
				if (mousedown) {
					setContext(context => {
						if (context) {
							setCanvasPosition(canvasPosition => {
								setPosition(position => {
									const newX = event.clientX - canvasPosition.x;
									const newY = event.clientY - canvasPosition.y;
									const newPosition: DrawProps = {
										from: {
											x: position.to.x === 0 && position.to.y === 0 ? newX : position.to.x,
											y: position.to.x === 0 && position.to.y === 0 ? newY : position.to.y,
										},
										to: {
											x: newX,
											y: newY,
										},
									};

									draw(context, newPosition);

									return newPosition;
								});

								return canvasPosition;
							});
						}
						return context;
					});
				}

				return mousedown;
			});
		};

		if (bookActive?.drawing.active) {
			addEventListener('mousedown', onMousedown);
			addEventListener('mouseup', onMouseup);
			addEventListener('mousemove', onMousemove);
		} else {
			removeEventListener('mousedown', onMousedown);
			removeEventListener('mouseup', onMouseup);
			removeEventListener('mousemove', onMousemove);
		}

		return () => {
			removeEventListener('mousedown', onMousedown);
			removeEventListener('mouseup', onMouseup);
			removeEventListener('mousemove', onMousemove);
		};
	}, [bookActive]);

	useEffect(() => {
		const canvas = canvasRef.current;
		if (canvas) {
			const newContext = canvas.getContext('2d');
			if (newContext) {
				setContext(newContext);
				updateCanvasPosition();
			} else {
				setContext(undefined);
			}
		}
	}, [canvasRef]);

	useEffect(() => {
		if (bookActive && context) {
			context.clearRect(0, 0, bookActive.pageDimensions.width * 2, bookActive.pageDimensions.height);
		}
	}, [visiblePages]);

	return bookActive ? (
		<canvas
			ref={canvasRef}
			width={
				bookActive.pageDimensions.orientation === 'horizontal'
					? bookActive.pageDimensions.width * 2
					: bookActive.pageDimensions.width
			}
			height={bookActive.pageDimensions.height}
			style={{
				position: 'absolute',
				left:
					bookActive.pageDimensions.orientation === 'horizontal'
						? `-${bookActive.pageDimensions.width}px`
						: undefined,
				zIndex: bookActive.drawing.active ? 9 : 8,
				cursor: bookActive.drawing.active ? 'crosshair' : 'default',
			}}
		/>
	) : null;
};

export default connect((state: Types.RootState) => ({
	bookActive: state.bookActive,
}))(Drawing);

import BookSlider from '../../BookDetail/components/BookSlider';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Grid from '@mui/material/Grid';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import BookIcon from '@mui/icons-material/Book';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import { useEffect, useState } from 'react';
import Types from '@taktik/common/types';
import { connect, useDispatch } from 'react-redux';
import reducers from '@taktik/common/reducers';
import { getViewportDimensions, pageFlipDelay } from '../../BookDetail';
import Typography from '@mui/material/Typography';

interface BookViewOptionsProps {
	bookActive: Types.BookActiveProps;
}

const BookViewOptions = (props: BookViewOptionsProps) => {
	const bookActive = props.bookActive;
	const dispatch = useDispatch();
	const [isFullscreen, setIsFullscreen] = useState(false);

	useEffect(() => {
		if (isFullscreen) {
			document.body.requestFullscreen();
		} else if (document.fullscreenElement) {
			document.exitFullscreen();
		}
	}, [isFullscreen]);

	return bookActive ? (
		<Grid item>
			<Grid container alignItems="center" spacing={1}>
				{bookActive.zoom > 1 && (
					<Grid item>
						<Typography fontWeight={800} color="#666">
							{Math.ceil(bookActive.zoom * 100)}%
						</Typography>
					</Grid>
				)}
				<Grid item>
					<ToggleButtonGroup value="in" color="primary" size="small">
						<ToggleButton
							value="in"
							onClick={() => {
								const newZoom = bookActive.zoom + 0.2;
								if (newZoom <= 3) {
									const newBookActive: Types.BookActiveProps = {
										...bookActive,
										zoom: newZoom,
										pageDimensions: getViewportDimensions(
											newZoom === 1.2 ? 'vertical' : bookActive.pageDimensions.orientation
										),
									};
									dispatch(reducers.interaktiv.bookActive.actions.set(newBookActive));
								}
							}}
						>
							<ZoomInIcon />
						</ToggleButton>
						<ToggleButton
							value="in"
							onClick={() => {
								const newZoom = bookActive.zoom - 0.2;
								if (newZoom >= 1) {
									const newBookActive: Types.BookActiveProps = {
										...bookActive,
										zoom: newZoom,
										pageDimensions: getViewportDimensions(bookActive.pageDimensions.orientation),
									};
									dispatch(reducers.interaktiv.bookActive.actions.set(newBookActive));
								}
							}}
							disabled={bookActive.zoom === 1}
						>
							<ZoomOutIcon />
						</ToggleButton>
					</ToggleButtonGroup>
				</Grid>
				<Grid item>
					<ToggleButtonGroup
						value={bookActive.pageDimensions.orientation}
						color="primary"
						size="small"
						onChange={() => {
							const newOrientation =
								bookActive.pageDimensions.orientation === 'horizontal' ? 'vertical' : 'horizontal';
							const newVisiblePages =
								newOrientation === 'horizontal'
									? bookActive.visiblePages[0] % 2
										? [bookActive.visiblePages[0] - 1, bookActive.visiblePages[0]]
										: [bookActive.visiblePages[0], bookActive.visiblePages[0] + 1]
									: [bookActive.visiblePages[0] < -1 ? -1 : bookActive.visiblePages[0]];
							const newBookActive = {
								...bookActive,
								controls: false,
								visiblePages: newVisiblePages,
								pageDimensions: getViewportDimensions(newOrientation as Types.BookOrientation),
							};
							dispatch(reducers.interaktiv.bookActive.actions.set(newBookActive));
							setTimeout(() => {
								dispatch(reducers.interaktiv.bookActive.actions.set({ ...newBookActive, controls: true }));
							}, pageFlipDelay);
						}}
						disabled={!bookActive.controls}
					>
						<ToggleButton value={'horizontal' as Types.BookOrientation}>
							<MenuBookIcon />
						</ToggleButton>
						<ToggleButton value={'vertical' as Types.BookOrientation}>
							<BookIcon />
						</ToggleButton>
					</ToggleButtonGroup>
				</Grid>
				<Grid item>
					<ToggleButtonGroup
						value="fullscreen"
						color="primary"
						size="small"
						onClick={() => setIsFullscreen(!isFullscreen)}
					>
						<ToggleButton value="fullscreen">
							{isFullscreen ? <FullscreenExitIcon /> : <FullscreenIcon />}
						</ToggleButton>
					</ToggleButtonGroup>
				</Grid>
			</Grid>
		</Grid>
	) : null;
};

export default connect((state: Types.RootState) => ({
	bookActive: state.bookActive,
}))(BookViewOptions);

import Types from '@taktik/common/types';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { connect, useDispatch } from 'react-redux';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Upload from '@taktik/components/Upload';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import reducers from '@taktik/common/reducers';
import ConfirmDialog from '@taktik/components/ConfirmDialog';
import { useMemo, useState } from 'react';
import _ from 'lodash';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

interface AudioEditorDialogProps {
	page: Types.BookPageDocument;
	audio: Types.AudioDocument;
	user: Types.UserSession;
	onClose: () => void;
	bookActive: Types.BookActiveProps;
}

const AudioEditorDialog = (props: AudioEditorDialogProps) => {
	const dispatch = useDispatch();
	const [audio, setAudio] = useState(props.audio);
	const bookActive = props.bookActive;
	const [remove, setRemove] = useState(false);

	const updateAudio = (newAudio: Types.AudioDocument) => {
		setAudio(newAudio);
		dispatch(
			reducers.interaktiv.bookPages.actions.update(
				'update/audio',
				{
					pageId: props.page._id,
					data: {
						audioId: audio._id,
						value: newAudio,
					},
				},
				props.user.token
			)
		);
	};

	const updatePosition = useMemo(
		() =>
			_.debounce((newAudio: Types.AudioDocument) => {
				updateAudio(newAudio);
			}, 1000),
		[]
	);

	return bookActive ? (
		<>
			<Dialog open onClose={() => props.onClose()} maxWidth="xs" fullWidth>
				<DialogContent>
					<Grid container spacing={3}>
						<Grid item md={12}>
							<Typography gutterBottom>Mp3 soubor</Typography>
							<Upload
								fileType="audio/mpeg"
								folder="book"
								path={`${bookActive.bookId}/${props.page._id}/audio`}
								filename={`${props.page.audio.length + 1}`}
								onDelete={() => {
									updateAudio({
										...audio,
										mp3: '',
									});
								}}
								onUpload={file => {
									updateAudio({
										...audio,
										mp3: file.path,
									});
								}}
								upload={{
									type: 'audio/mpeg',
									path: audio.mp3,
								}}
							/>
						</Grid>
						<Grid item md={12}>
							<Typography gutterBottom>Ogg soubor</Typography>
							<Upload
								fileType="audio/ogg"
								folder="book"
								path={`${bookActive.bookId}/${props.page._id}/audio`}
								filename={`${props.page.audio.length + 1}`}
								onDelete={() => {
									updateAudio({
										...audio,
										ogg: '',
									});
								}}
								onUpload={file => {
									updateAudio({
										...audio,
										ogg: file.path,
									});
								}}
								upload={{
									type: 'audio/ogg',
									path: audio.ogg,
								}}
							/>
						</Grid>
						<Grid item md={6}>
							<Typography>Poloha zhora</Typography>
							<TextField
								type="number"
								value={audio.y}
								InputProps={{ endAdornment: '%' }}
								onChange={event => {
									const newAudio = {
										...audio,
										y: parseInt(event.target.value),
									};
									setAudio(newAudio);
									updatePosition(newAudio);
								}}
							/>
						</Grid>
						<Grid item md={6}>
							<Typography>Poloha zleva</Typography>
							<TextField
								type="number"
								value={audio.x}
								InputProps={{ endAdornment: '%' }}
								onChange={event => {
									const newAudio = {
										...audio,
										x: parseInt(event.target.value),
									};
									setAudio(newAudio);
									updatePosition(newAudio);
								}}
							/>
						</Grid>
						<Grid item md={12}>
							<FormControlLabel
								control={
									<Checkbox
										onChange={() => {
											updateAudio({
												...audio,
												ua: !audio.ua,
											});
										}}
										checked={!!audio.ua}
									/>
								}
								label="UA Audio"
							/>
						</Grid>
						<Grid item md={4}>
							<Button variant="outlined" fullWidth onClick={() => props.onClose()}>
								Zavřít
							</Button>
						</Grid>
						<Grid item md={2}></Grid>
						<Grid item md={6}>
							<Button onClick={() => setRemove(true)} color="error">
								Smazat audio
							</Button>
						</Grid>
					</Grid>
				</DialogContent>
			</Dialog>
			{remove && (
				<ConfirmDialog
					type="remove"
					title="Opravdu chcete smazat tuto audio stopu?"
					onClose={() => {
						setRemove(false);
					}}
					onSubmit={() => {
						dispatch(reducers.common.upload.actions.remove(audio.mp3, props.user.token));
						dispatch(reducers.common.upload.actions.remove(audio.ogg, props.user.token));
						dispatch(
							reducers.interaktiv.bookPages.actions.update(
								'remove/audio',
								{
									pageId: props.page._id,
									data: {
										audioId: audio._id,
									},
								},
								props.user.token
							)
						);
						setRemove(false);
						props.onClose();
					}}
				/>
			)}
		</>
	) : null;
};

export default connect((state: Types.RootState) => ({
	user: state.user,
	bookActive: state.bookActive,
}))(AudioEditorDialog);

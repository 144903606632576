import CircularProgress, { CircularProgressProps } from '@mui/material/CircularProgress';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import useStyles from '../../styles';
import clsx from 'clsx';
import Box from '@mui/material/Box';
import T from '@taktik/components/T';

interface LoaderProps {
	inline?: boolean;
	progress?: {
		all: number;
		loaded: number;
		error: number;
	};
	color?: CircularProgressProps['color'];
	height?: string;
}

const Loader = (props: LoaderProps) => {
	const classes = useStyles();

	return (
		<div
			className={clsx(classes.loaderContainer, props.inline && 'inline')}
			style={{
				height: props.height,
			}}
		>
			{props.progress && props.progress.all > 0 && (
				<Box width={400} textAlign="center">
					<Box mb={2}>
						<Typography>
							<T id="question.loadingData" />
						</Typography>
					</Box>
					<LinearProgress
						variant="determinate"
						color="primary"
						value={((props.progress.loaded + props.progress.error) / props.progress.all) * 100}
					/>
				</Box>
			)}
			{(!props.progress || props.progress.all === 0) && <CircularProgress color={props.color} />}
		</div>
	);
};

export default Loader;

import AppBar from '@mui/material/AppBar';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import Grid from '@mui/material/Grid';
import Types from '@taktik/common/types';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles({
	html: {
		'& p': {
			marginTop: 0,
		},
	},
	thumb: {
		height: 120,
		overflow: 'hidden',
	},
});

interface PresentationDialogProps {
	onClose: () => void;
	bonus: Types.Bonus;
}

const PresentationDialog = (props: PresentationDialogProps) => {
	const classes = useStyles();
	const bonus = props.bonus;
	const leftImage = bonus.images[0];

	return (
		<Dialog open={true} onClose={() => props.onClose()}>
			<AppBar sx={{ position: 'relative' }}>
				<Toolbar>
					<Grid container justifyContent="space-between" alignItems="center">
						<Grid item>
							<Typography variant="h6">{bonus.title}</Typography>
						</Grid>
						<Grid item>
							<IconButton edge="start" color="inherit" onClick={props.onClose} className="close-dialog">
								<CloseIcon />
							</IconButton>
						</Grid>
					</Grid>
				</Toolbar>
			</AppBar>
			<Box p={3}>
				<Grid container spacing={2}>
					{leftImage && (
						<Grid item md={4}>
							<img
								src={`${process.env.REACT_APP_FILES}${leftImage.src}`}
								title={leftImage.title}
								width="100%"
							/>
						</Grid>
					)}
					<Grid item flex={1} className={classes.html}>
						<div dangerouslySetInnerHTML={{ __html: bonus.html }}></div>
					</Grid>
				</Grid>
				<Grid container spacing={2}>
					{bonus.images.slice(1).map((image, key) => (
						<Grid item md={4} className={classes.thumb} key={`presentation-image-${key}`}>
							<img
								src={`${process.env.REACT_APP_FILES}${image.src}`}
								title={image.title}
								width="100%"
								height="100%"
							/>
						</Grid>
					))}
				</Grid>
			</Box>
		</Dialog>
	);
};

export default PresentationDialog;

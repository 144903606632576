import Types from '@taktik/common/types';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Draggable from 'react-draggable';
import Paper, { PaperProps } from '@mui/material/Paper';
import { connect, useDispatch } from 'react-redux';
import reducers from '@taktik/common/reducers';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormLabel from '@mui/material/FormLabel';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import theme from '../../../theme';
import Divider from '@mui/material/Divider';
import DeleteIcon from '@mui/icons-material/Delete';
import { useMemo, useState } from 'react';
import _ from 'lodash';

const PaperComponent = (props: PaperProps) => {
	return (
		<Draggable handle="#draggable-dialog" cancel={'[class*="MuiDialogContent-root"]'}>
			<Paper {...props} />
		</Draggable>
	);
};

interface AreaDialogProps {
	user: Types.UserSession;
	open: boolean;
	page: Types.BookPageDocument;
	area: Types.AreaDocument;
	onClose: () => void;
	bookActive: Types.BookActiveProps;
}

const AreaDialog = (props: AreaDialogProps) => {
	const page = props.page;
	const area = props.area;
	const bookActive = props.bookActive;
	const dispatch = useDispatch();
	const [canvas, setCanvas] = useState(area.canvas);

	const updateCanvas = useMemo(
		() =>
			_.debounce((newCanvas: string) => {
				dispatch(
					reducers.interaktiv.bookPages.actions.update(
						'update/areaCanvas',
						{
							pageId: page._id,
							data: {
								areaId: area._id,
								value: newCanvas,
							},
						},
						props.user.token
					)
				);
			}, 1000),
		[props.user.token, dispatch]
	);

	const setBookActiveMode = (mode: Types.BookEditTransformModes) => {
		if (bookActive) {
			dispatch(
				reducers.interaktiv.bookActive.actions.set({
					...bookActive,
					editor: {
						...bookActive.editor,
						transform: {
							...bookActive.editor.transform,
							mode,
						},
					},
				})
			);
		}
	};

	return (
		<Dialog
			open={props.open}
			onClose={() => props.onClose()}
			PaperComponent={PaperComponent}
			hideBackdrop
			maxWidth="md"
			fullWidth
			aria-labelledby="draggable-dialog"
		>
			{area && (
				<>
					<DialogTitle
						style={{ cursor: 'move' }}
						id="draggable-dialog"
						onMouseOver={() => setBookActiveMode('areaDetail')}
						onMouseLeave={() => setBookActiveMode('workspace')}
					>
						Oblast <Chip label={area.id} color="primary" size="small" />
					</DialogTitle>
					<DialogContent
						onMouseOver={() => setBookActiveMode('areaDetail')}
						onMouseLeave={() => setBookActiveMode('workspace')}
					>
						<Grid container spacing={1}>
							<Grid item md={12}>
								<FormLabel color="primary">Vrstva</FormLabel>
							</Grid>
							<Grid item md={12}>
								<FormControl fullWidth>
									<Select
										value={area.layer}
										onChange={event => {
											const newLayer = event.target.value;
											dispatch(
												reducers.interaktiv.bookPages.actions.update(
													'update/areaLayer',
													{
														pageId: page._id,
														data: {
															areaId: area._id,
															value: newLayer,
														},
													},
													props.user.token
												)
											);
										}}
									>
										{page.overlays.map((layer, key) => (
											<MenuItem value={key} key={`area-overlay-${key}`}>
												{key + 1}. vrstva
											</MenuItem>
										))}
									</Select>
								</FormControl>
							</Grid>
							<Grid item md={12}>
								<FormLabel color="primary">Typ oblasti</FormLabel>
							</Grid>
							<Grid item md={12}>
								<FormControl fullWidth>
									<Select
										value={area.type}
										onChange={event => {
											const newType = event.target.value;
											dispatch(
												reducers.interaktiv.bookPages.actions.update(
													'update/areaType',
													{
														pageId: page._id,
														data: {
															areaId: area._id,
															value: newType,
														},
													},
													props.user.token
												)
											);
										}}
									>
										<MenuItem value="active">
											<span
												style={{ width: 10, height: 10, background: '#00ff00', marginRight: theme.spacing(1) }}
											></span>{' '}
											Klikateľná
										</MenuItem>
										<MenuItem value="both">
											<span
												style={{ width: 10, height: 10, background: '#005eff', marginRight: theme.spacing(1) }}
											></span>{' '}
											Klikateľná + Riešenie
										</MenuItem>
										<MenuItem value="passive">
											<span
												style={{ width: 10, height: 10, background: '#ff7300', marginRight: theme.spacing(1) }}
											></span>{' '}
											Riešenie
										</MenuItem>
										<MenuItem value="canvas">
											<span
												style={{ width: 10, height: 10, background: '#ee00ff', marginRight: theme.spacing(1) }}
											></span>{' '}
											Písanka
										</MenuItem>
									</Select>
								</FormControl>
							</Grid>
							{area.type === 'canvas' && (
								<>
									<Grid item md={12}>
										<FormLabel>Súradnice</FormLabel>
									</Grid>
									<Grid item md={12}>
										<TextField
											multiline
											rows={10}
											value={canvas}
											onChange={event => {
												setCanvas(event.target.value);
												updateCanvas(event.target.value);
											}}
											fullWidth
										/>
									</Grid>
								</>
							)}
							<Grid item md={12}>
								<FormLabel>Poloha</FormLabel>
							</Grid>
							<Grid item md={12}>
								<Grid container spacing={2}>
									<Grid item md={6}>
										<TextField
											label="Zhora"
											value={area.position.top}
											InputProps={{
												endAdornment: <InputAdornment position="end">%</InputAdornment>,
											}}
											onChange={event => {
												const newValue = event.target.value;
												dispatch(
													reducers.interaktiv.bookPages.actions.update(
														'update/areaPosition',
														{
															pageId: page._id,
															data: {
																areaId: area._id,
																value: {
																	...area.position,
																	top: newValue,
																},
															},
														},
														props.user.token
													)
												);
											}}
											fullWidth
										/>
									</Grid>
									<Grid item md={6}>
										<TextField
											label="Zľava"
											value={area.position.left}
											InputProps={{
												endAdornment: <InputAdornment position="end">%</InputAdornment>,
											}}
											onChange={event => {
												const newValue = event.target.value;
												dispatch(
													reducers.interaktiv.bookPages.actions.update(
														'update/areaPosition',
														{
															pageId: page._id,
															data: {
																areaId: area._id,
																value: {
																	...area.position,
																	left: newValue,
																},
															},
														},
														props.user.token
													)
												);
											}}
											fullWidth
										/>
									</Grid>
								</Grid>
							</Grid>
							<Grid item md={12}>
								<FormLabel>Aktivne oblasti</FormLabel>
							</Grid>
							<Grid item md={12}>
								{area.results.map(result => (
									<Box key={`area-result-${area.id}-${result}`} display="inline-block" mr={1} mb={1}>
										<Chip
											label={<Typography>{result}</Typography>}
											color="primary"
											onDelete={() => {
												dispatch(
													reducers.interaktiv.bookPages.actions.update(
														'remove/areaResult',
														{
															pageId: page._id,
															data: {
																areaId: area._id,
																value: result,
															},
														},
														props.user.token
													)
												);
											}}
										/>
									</Box>
								))}
								{page.areas.map(a => {
									return (a.type === 'active' || a.type === 'both') &&
										area.results.indexOf(a.id) === -1 &&
										a.id !== area.id ? (
										<Box p={1} component="span" display="inline-block" key={`area-result-${area.id}`}>
											<Button
												variant="outlined"
												onClick={() => {
													dispatch(
														reducers.interaktiv.bookPages.actions.update(
															'add/areaResult',
															{
																pageId: page._id,
																data: {
																	areaId: area._id,
																	value: a.id,
																},
															},
															props.user.token
														)
													);
												}}
												size="small"
											>
												{a.id}
											</Button>
										</Box>
									) : null;
								})}
							</Grid>
							{(area.type === 'active' || area.type === 'both') && (
								<>
									<Grid item md={12}>
										<FormLabel>Cielove oblasti</FormLabel>
									</Grid>
									<Grid item md={12}>
										{page.areas
											.filter(a => a.results.indexOf(area.id) > -1)
											.map(a => (
												<Box key={`area-area.id-${a.id}-${a.id}`} display="inline-block" mr={1} mb={1}>
													<Chip
														label={<Typography>{a.id}</Typography>}
														color="primary"
														onDelete={() => {
															dispatch(
																reducers.interaktiv.bookPages.actions.update(
																	'remove/areaResult',
																	{
																		pageId: page._id,
																		data: {
																			areaId: a._id,
																			value: area.id,
																		},
																	},
																	props.user.token
																)
															);
														}}
													/>
												</Box>
											))}
										{page.areas.map(a =>
											area.results.indexOf(a.id) === -1 && a.id !== area.id ? (
												<Box p={1} component="span" display="inline-block" key={`area-result-${area.id}`}>
													<Button
														variant="outlined"
														onClick={() => {
															dispatch(
																reducers.interaktiv.bookPages.actions.update(
																	'add/areaResult',
																	{
																		pageId: page._id,
																		data: {
																			areaId: a._id,
																			value: area.id,
																		},
																	},
																	props.user.token
																)
															);
														}}
														size="small"
													>
														{a.id}
													</Button>
												</Box>
											) : null
										)}
									</Grid>
								</>
							)}
							<Grid item md={12}>
								<Box my={3}>
									<Divider />
								</Box>
							</Grid>
							<Grid item md={12}>
								<Button
									variant="outlined"
									color="error"
									startIcon={<DeleteIcon></DeleteIcon>}
									onClick={() => {
										dispatch(
											reducers.interaktiv.bookPages.actions.update(
												'remove/area',
												{
													pageId: page._id,
													data: {
														areaId: area._id,
													},
												},
												props.user.token
											)
										);
									}}
									id="area-detail-remove"
								>
									Odstránit oblast
								</Button>
							</Grid>
						</Grid>
					</DialogContent>
				</>
			)}
		</Dialog>
	);
};

export default connect((state: Types.RootState) => ({
	user: state.user,
	bookActive: state.bookActive,
}))(AreaDialog);

import ChevronLeft from '@mui/icons-material/ChevronLeft';
import ChevronRight from '@mui/icons-material/ChevronRight';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Reducers from '@taktik/common/reducers';
import Types from '@taktik/common/types';
import { useEffect, useRef, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import Areas from './components/Areas';
import AudioFiles from './components/Audio';
import Bonuses from './components/Bonuses';
import BookVertical from './components/BookVertical';
import BookHorizontal from './components/BookHorizontal';
import theme from '../../theme';
import makeStyles from '@mui/styles/makeStyles';
import LayersEditor from '../BookEditor/components/LayersEditor';
import AreasEditor from '../BookEditor/components/AreasEditor';
import Button from '@mui/material/Button';
import BonusesEditor from '../BookEditor/components/BonusesEditor';
import Drawing from './components/Drawing';
import BookOptions from './components/Options';
import AudioPlayer from './components/AudioPlayer';
import AudiosEditor from '../BookEditor/components/AudiosEditor';

const useStyles = makeStyles({
	nav: {
		cursor: 'pointer',
		transition: 'opacity 0.5s',
		color: theme.palette.common.white,
		opacity: 0.3,
		'&:hover': {
			opacity: 1,
		},
		'& > svg': {
			filter: 'drop-shadow(3px 5px 2px rgb(0 0 0 / 0.4))',
		},
	},
	shadow: {
		position: 'absolute',
		transition: 'opacity 0.5s',
	},
	flipBook: {
		display: 'flex',
		position: 'relative',

		'& .flip-book': {
			position: 'relative',
			perspective: 3000,
			'& #cover': {
				position: 'absolute',
			},
		},
		'& .flip': {
			width: '100%',
			height: '100%',
			position: 'absolute',
			top: 0,
			left: 0,
			transformStyle: 'preserve-3d',
			transform: 'rotateY(0deg)',
			transition: 'transform 1s',
			color: '#000',
		},
		'& .overlay': {
			position: 'absolute',
			left: 0,
			top: 0,

			'& .areas': {
				zIndex: 10,
				position: 'absolute',
				left: 0,
				top: 0,
				width: '100%',
				height: '100%',
				'& .area': {
					position: 'absolute',
					transition: 'opacity 0.5s',
				},
				'& .area.active, & .area.both': {
					cursor: 'pointer',
				},
			},

			'& .audios': {
				position: 'absolute',
				zIndex: 1,

				'& .audio': {
					position: 'absolute',
					fontSize: '0.4em',
					background: '#fff',
					borderRadius: '100%',
					lineHeight: '2.2em',
					border: '1px solid #f37416',
					color: '#f37416',
					opacity: 1,
					transition: 'color 0.4s, background 0.4s',
					textAlign: 'center',

					'&.ua-audio': {
						background:
							'linear-gradient(0deg, rgba(255,213,0,1) 0%, rgba(255,213,0,1) 50%, rgba(1,90,187,1) 50%, rgba(1,90,187,1) 100%)',
						border: 'none',

						'& > .ua-icon': {
							background: '#fff',
							display: 'block',
							width: 'calc(100% - 2px)',
							borderRadius: '100%',
							margin: '1px',
							color: 'rgba(1,90,187,1)',
						},

						'&:hover > .ua-icon, &.active > .ua-icon': {
							background:
								'linear-gradient(0deg, rgba(255,213,0,1) 0%, rgba(255,213,0,1) 50%, rgba(1,90,187,1) 50%, rgba(1,90,187,1) 100%)',
							color: '#fff',
						},

						'&:hover, &.active': {
							background: 'none',
							color: '#fff',
						},
					},

					'&:hover, &.active': {
						background: '#f37416',
						color: '#fff',
					},
				},
			},
		},
		'& .back': {
			position: 'absolute',
			width: '100%',
			height: '100%',
			top: 0,
			left: 0,
			zIndex: 99,
			transform: 'rotateY(180deg)',
			backfaceVisibility: 'hidden',
		},
		'& .front': {
			position: 'absolute',
			width: '100%',
			height: '100%',
			top: 0,
			left: 0,
			boxSizing: 'border-box',
			zIndex: 8,
			'& .shadow': {
				position: 'absolute',
				boxShadow: 'inset 10px 0px 20px -10px rgb(0 0 0 / 25%)',
				left: 0,
				top: 0,
				right: 0,
				bottom: 0,
				zIndex: 8,
			},
		},
	},
});

const legendWidth = 300;
const bookSpacing = 40;
export const getViewportDimensions = (
	bookOrientation: Types.BookOrientation,
	bookLegend?: boolean,
	bookBonuses?: boolean
): Types.BookPageDimensions => {
	const { innerWidth, innerHeight } = window;
	const verticalBookRatioH = 12 / 17;
	const horizontalBookRatioH = 24 / 17;
	let width = innerWidth - 200;
	let height = innerHeight - 160;
	if (bookLegend) {
		width = width - legendWidth;
	}
	if (bookBonuses) {
		width = width - legendWidth;
	}

	if (bookOrientation === 'horizontal') {
		const newWidth = (height * horizontalBookRatioH) / 2;
		const newHeight = height;
		if (newWidth * 2 < width) {
			return {
				width: newWidth,
				height: newHeight,
				orientation: bookOrientation,
			};
		}

		return {
			width: width / 2,
			height: width * verticalBookRatioH,
			orientation: bookOrientation,
		};
	} else {
		return {
			width: height * verticalBookRatioH,
			height: height,
			orientation: bookOrientation,
		};
	}
};

const defaultBookDrawing: Types.BookActiveDrawingProps = {
	active: false,
	mode: 'pencil',
	size: 10,
	color: '#00af50',
};

const defaultBookEditor: Types.BookActiveEditorProps = {
	mode: 'preview',
	tool: 'select',
	selected: [],
	layer: {
		left: 0,
		right: 0,
	},
	transform: {
		mode: 'workspace',
		x: 0,
		y: 0,
		z: 1,
	},
};

const defaultBookCanvas: Types.BookActiveCanvasProps = {
	speed: 'normal',
};

export const pageFlipDelay = 750;

interface BookDetailProps {
	book: Types.BookDocument;
	bookPages: Types.BookPageDocument[];
	bookActive: Types.BookActiveProps;
	user: Types.UserSession;
	panning?: boolean;
}

const BookDetail = (props: BookDetailProps) => {
	const dispatch = useDispatch();
	const classes = useStyles();
	const book = props.book;
	const bookActive = props.bookActive;
	const bookLegend = bookActive?.legend;
	const bookBonuses = bookActive?.bonuses;
	const bookOrientation = bookActive?.pageDimensions?.orientation;
	const editMode = bookActive?.editor.mode === 'preview' ? false : bookActive?.editor.mode;
	const tool = bookActive?.editor.tool;
	const [leftPage, setLeftPage] = useState<Types.BookPageDocument>();
	const [rightPage, setRightPage] = useState<Types.BookPageDocument>();
	const visiblePages = bookActive?.visiblePages || [-2, -1];
	const [showOverlay, setShowOverlay] = useState(false);
	const [pages, setPages] = useState<Types.BookPageDocument[]>([]);
	const [shadow, setShadow] = useState(false);
	const [hasUaAudio, setHasUaAudio] = useState(false);
	const viewportRef = useRef<HTMLDivElement>(null);

	const nextPage = (bookActive: Types.BookActiveProps) => {
		if (bookActive && bookActive.controls) {
			setPages(pages => {
				let newVisiblePages: number[] = [];
				if (bookActive.pageDimensions.orientation === 'horizontal') {
					newVisiblePages =
						visiblePages[0] !== null && visiblePages[0] % 2
							? [
									visiblePages[0] !== null ? visiblePages[0] + 1 : 0,
									visiblePages[0] !== null ? visiblePages[0] + 2 : -1,
							  ]
							: [
									visiblePages[0] !== null ? visiblePages[0] + 2 : 0,
									visiblePages[0] !== null ? visiblePages[0] + 3 : -1,
							  ];
					if (newVisiblePages[1] > pages.length) {
						return pages;
					}
				} else {
					newVisiblePages = [visiblePages[0] !== null ? visiblePages[0] + 1 : 0];
					if (newVisiblePages[0] > pages.length - 2) {
						return pages;
					}
				}
				setShowOverlay(false);

				dispatch(
					Reducers.interaktiv.bookActive.actions.set({
						...bookActive,
						visiblePages: newVisiblePages,
						controls: false,
					})
				);

				setTimeout(() => {
					dispatch(
						Reducers.interaktiv.bookActive.actions.set({
							...bookActive,
							visiblePages: newVisiblePages,
							controls: true,
							editor: {
								...bookActive.editor,
								layer: {
									left: editMode === 'layers' ? -1 : 0,
									right: editMode === 'layers' ? -1 : 0,
								},
							},
							canvas: defaultBookCanvas,
						})
					);
					setShowOverlay(true);
				}, pageFlipDelay);

				return pages;
			});
		}
	};

	const prevPage = (bookActive: Types.BookActiveProps) => {
		if (bookActive && bookActive.controls) {
			let newVisiblePages: number[] = [];
			if (bookActive.pageDimensions.orientation === 'horizontal') {
				newVisiblePages =
					visiblePages[0] !== null && visiblePages[0] % 2
						? [
								visiblePages[0] !== null ? visiblePages[0] - 1 : 0,
								visiblePages[0] !== null ? visiblePages[0] - 2 : -1,
						  ]
						: [
								visiblePages[0] !== null ? visiblePages[0] - 2 : 0,
								visiblePages[0] !== null ? visiblePages[0] - 1 : -1,
						  ];
				if (newVisiblePages[0] < -2) {
					return pages;
				}
			} else {
				newVisiblePages = [visiblePages[0] !== null ? visiblePages[0] - 1 : 0];
				if (newVisiblePages[0] < -1) {
					return pages;
				}
			}

			setShowOverlay(false);

			dispatch(
				Reducers.interaktiv.bookActive.actions.set({
					...bookActive,
					visiblePages: newVisiblePages,
					controls: false,
				})
			);

			setTimeout(() => {
				dispatch(
					Reducers.interaktiv.bookActive.actions.set({
						...bookActive,
						visiblePages: newVisiblePages,
						controls: true,
						editor: {
							...bookActive.editor,
							layer: {
								left: editMode === 'layers' ? -1 : 0,
								right: editMode === 'layers' ? -1 : 0,
							},
						},
						canvas: defaultBookCanvas,
					})
				);
				setShowOverlay(true);
			}, pageFlipDelay);
		}
	};

	// fetch pages on book change
	useEffect(() => {
		if (book) {
			const pageDimensions = getViewportDimensions('horizontal');
			const defaultBookActive: Types.BookActiveProps = {
				bookId: book._id,
				visiblePages: pageDimensions.orientation === 'horizontal' ? [-2, -1] : [-1],
				controls: true,
				legend: bookActive?.legend || false,
				bonuses: bookActive?.bonuses || false,
				zoom: 1,
				pageDimensions,
				drawing: defaultBookDrawing,
				editor: defaultBookEditor,
				canvas: defaultBookCanvas,
			};
			dispatch(Reducers.interaktiv.bookActive.actions.set(defaultBookActive));
			dispatch(Reducers.interaktiv.bookPages.actions.get(book._id, props.user.token));
			setPages([]);
		}
	}, [book]);

	// filter and set pages
	useEffect(() => {
		if (bookActive) {
			dispatch(
				Reducers.interaktiv.bookActive.actions.set({
					...bookActive,
					editor: {
						...bookActive.editor,
						layer: {
							left: 0,
							right: 0,
						},
					},
				})
			);
		}
		const newPages = props.bookPages.filter(bp => bp.bookId === book._id);
		let hasUaAudio = newPages.filter(p => p.audio.find(a => a.ua)).length > 0;
		setHasUaAudio(hasUaAudio);
		setPages(newPages);
	}, [props.bookPages]);

	// handle resize window
	useEffect(() => {
		if (bookActive) {
			const handleResize = () => {
				const newPageDimensions = getViewportDimensions(
					bookActive.pageDimensions.orientation,
					bookLegend,
					bookBonuses
				);
				const newBookActive = {
					...bookActive,
					pageDimensions: newPageDimensions,
				};
				dispatch(Reducers.interaktiv.bookActive.actions.set(newBookActive));
			};

			window.addEventListener('resize', handleResize);
			return () => window.removeEventListener('resize', handleResize);
		}
	}, [bookActive]);

	// switching legend or bonuses
	useEffect(() => {
		if (bookActive) {
			const newPageDimensions = getViewportDimensions(
				bookActive.pageDimensions.orientation,
				bookLegend,
				bookBonuses
			);
			const newBookActive = {
				...bookActive,
				pageDimensions: newPageDimensions,
			};
			dispatch(Reducers.interaktiv.bookActive.actions.set(newBookActive));
		}
	}, [bookLegend, bookBonuses]);

	// keyboard navigation
	useEffect(() => {
		const handleKeydown = (e: KeyboardEvent) => {
			if (e.key === 'ArrowRight') {
				nextPage(bookActive);
			}
			if (e.key === 'ArrowLeft') {
				prevPage(bookActive);
			}
		};

		document.addEventListener('keydown', handleKeydown);

		return () => {
			document.removeEventListener('keydown', handleKeydown);
		};
	}, [bookActive]);

	// auto-flip first page
	useEffect(() => {
		const timeout = setTimeout(() => {
			const pageDimensions = getViewportDimensions('horizontal');
			const defaultBookActive: Types.BookActiveProps = {
				bookId: book._id,
				legend: false,
				bonuses: false,
				visiblePages: pageDimensions.orientation === 'horizontal' ? [-2, -1] : [-1],
				controls: true,
				zoom: 1,
				pageDimensions,
				drawing: defaultBookDrawing,
				editor: defaultBookEditor,
				canvas: defaultBookCanvas,
			};
			dispatch(Reducers.interaktiv.bookActive.actions.set(defaultBookActive));
			setTimeout(() => {
				dispatch(
					Reducers.interaktiv.bookActive.actions.set({ ...defaultBookActive, visiblePages: [0, 1] })
				);
				setShowOverlay(true);
			}, 300);
		}, 100);
		return () => {
			clearTimeout(timeout);
		};
	}, []);

	// Set left & right pages
	useEffect(() => {
		setLeftPage(undefined);
		setRightPage(undefined);
		setTimeout(() => {
			setLeftPage(pages.find(p => p.page === visiblePages[0]));
			setRightPage(pages.find(p => p.page === visiblePages[1]));
		}, pageFlipDelay);
	}, [visiblePages, showOverlay]);

	useEffect(() => {
		setLeftPage(pages.find(p => p.page === visiblePages[0]));
		setRightPage(pages.find(p => p.page === visiblePages[1]));
	}, [pages]);

	// Show shadows
	useEffect(() => {
		if (bookOrientation === 'vertical') {
			setShadow(false);
		} else {
			if (visiblePages[0] > -1 && visiblePages[0] < pages.length - 2) {
				setShadow(true);
			} else {
				setShadow(false);
			}
		}
	}, [visiblePages, bookOrientation, pages]);

	// Reset bookActive on component unmount
	useEffect(() => {
		return () => {
			dispatch(Reducers.interaktiv.bookActive.actions.set(undefined));
		};
	}, []);

	return (
		<Box
			style={{
				paddingTop: bookSpacing / 2,
			}}
			ref={viewportRef}
		>
			<Grid
				container
				justifyContent="space-evenly"
				alignItems="center"
				flexWrap="nowrap"
				sx={{
					minHeight: 'calc(100vh - 182px)',
				}}
			>
				<Grid
					item
					sx={{
						position: 'absolute',
						left: bookActive?.legend ? 16 + 280 : theme.spacing(2),
						transition: 'left 0.5s',
						top: 'calc(50vh - 40px)',
						zIndex: 9,
					}}
				>
					<div
						onClick={() => prevPage(bookActive)}
						className={classes.nav}
						style={{
							opacity: visiblePages[0] === -2 ? 0 : undefined,
						}}
						id="book-nav-prev"
					>
						<ChevronLeft style={{ fontSize: 80 }} />
					</div>
				</Grid>
				<Grid
					item
					sx={{
						transform: `scale(${bookActive?.zoom || 1})`,
						transition: 'all 0.5s',
						transformOrigin: bookActive && bookActive.zoom > 1 ? 'top' : 'center',
					}}
				>
					<Box
						sx={{
							marginBottom: bookActive && bookActive.zoom > 1 ? 20 / bookActive.zoom : undefined,
						}}
					>
						<div
							style={{
								alignItems: 'center',
								justifyContent: 'center',
								display: 'flex',
							}}
						>
							{book && bookActive && (
								<Box
									className={classes.flipBook}
									style={{
										paddingLeft:
											bookActive.pageDimensions.orientation === 'horizontal'
												? bookActive.pageDimensions.width
												: 0,
									}}
								>
									{showOverlay && (
										<div className="overlay">
											{leftPage && (
												<div
													className="left"
													style={{
														position: 'absolute',
													}}
												>
													{!editMode && <AudioFiles page={leftPage} />}
													{!editMode && <Areas page={leftPage} />}
													{editMode === 'areas' && (
														<AreasEditor
															page={leftPage}
															layer={editMode === 'areas' ? bookActive.editor.layer.left : undefined}
														/>
													)}
													{editMode === 'audio' && <AudiosEditor page={leftPage} />}
													{(editMode === 'layers' || editMode === 'areas') && (
														<LayersEditor
															page={leftPage}
															position="left"
															addAreaMode={editMode === 'areas'}
															iconsOnly={editMode === 'areas' && (tool === 'select' || tool === 'connect')}
														/>
													)}
													{!editMode && (
														<Bonuses
															page={leftPage}
															position="left"
															onOpen={() => {
																if (bookActive) {
																	dispatch(
																		Reducers.interaktiv.bookActive.actions.set({
																			...bookActive,
																			controls: false,
																		})
																	);
																}
															}}
															onClose={() => {
																if (bookActive) {
																	dispatch(
																		Reducers.interaktiv.bookActive.actions.set({
																			...bookActive,
																			controls: true,
																		})
																	);
																}
															}}
														/>
													)}
													{editMode === 'bonuses' && <BonusesEditor page={leftPage} position="left" />}
												</div>
											)}
											{rightPage && (
												<div
													className="right"
													style={{
														position: 'absolute',
														width: bookActive.pageDimensions.width,
														left: bookActive.pageDimensions.width,
														top: 0,
													}}
												>
													{!editMode && <AudioFiles page={rightPage} />}
													{!editMode && <Areas page={rightPage} />}
													{(editMode === 'layers' || editMode === 'areas') && (
														<LayersEditor
															page={rightPage}
															position="right"
															addAreaMode={editMode === 'areas'}
															iconsOnly={editMode === 'areas' && (tool === 'select' || tool === 'connect')}
														/>
													)}
													{editMode === 'areas' && (
														<AreasEditor
															page={rightPage}
															layer={editMode === 'areas' ? bookActive.editor.layer.right : undefined}
														/>
													)}
													{editMode === 'audio' && <AudiosEditor page={rightPage} />}
													{!editMode && (
														<Bonuses
															page={rightPage}
															position="right"
															onOpen={() => {
																if (bookActive) {
																	dispatch(
																		Reducers.interaktiv.bookActive.actions.set({
																			...bookActive,
																			controls: false,
																		})
																	);
																}
															}}
															onClose={() => {
																if (bookActive) {
																	dispatch(
																		Reducers.interaktiv.bookActive.actions.set({
																			...bookActive,
																			controls: true,
																		})
																	);
																}
															}}
														/>
													)}
													{editMode === 'bonuses' && <BonusesEditor page={rightPage} position="right" />}
												</div>
											)}
											{editMode === 'layers' && visiblePages[1] > pages.length - 1 && (
												<div
													className="right"
													style={{
														position: 'absolute',
														width: bookActive.pageDimensions.width,
														height: bookActive.pageDimensions.height,
														left: bookActive.pageDimensions.width,
														top: 0,
														background: '#ffffff66',
														zIndex: 9,
													}}
												>
													<Grid container width="100%" height="100%" alignItems="center">
														<Grid item textAlign="center" flex={1}>
															<Button
																variant="contained"
																color="secondary"
																onClick={() => {
																	dispatch(
																		Reducers.interaktiv.bookPages.actions.add(bookActive.bookId, props.user.token)
																	);
																}}
																className="add-book-page-button"
															>
																Přidat stranu
															</Button>
														</Grid>
													</Grid>
												</div>
											)}
										</div>
									)}
									<div
										className="flip-book"
										style={{
											width: bookActive.pageDimensions.width,
											height: bookActive.pageDimensions.height,
											cursor:
												bookActive.editor.mode === 'areas' || bookActive.editor.mode === 'layers'
													? props.panning
														? 'grabbing'
														: 'grab'
													: 'default',
										}}
									>
										{/* Inner shadows */}
										{bookActive.pageDimensions.orientation === 'horizontal' && (
											<>
												<div
													className={classes.shadow}
													style={{
														transitionDelay: shadow ? '0.5s' : '0s',
														transitionDuration: shadow ? '0.5s' : '0s',
														opacity: shadow ? 1 : 0,
														marginLeft: -bookActive.pageDimensions.width,
														width: bookActive.pageDimensions.width,
														height: bookActive.pageDimensions.height,
														boxShadow: 'inset -10px 0px 20px -10px rgb(0 0 0 / 25%)',
														zIndex: 8,
													}}
												></div>
												<div
													className={classes.shadow}
													style={{
														transitionDelay: shadow ? '0.5s' : '0s',
														transitionDuration: shadow ? '0.5s' : '0s',
														opacity: shadow ? 1 : 0,
														width: bookActive.pageDimensions.width,
														height: bookActive.pageDimensions.height,
														borderLeft: '1px solid rgba(0, 0, 0, 0.2)',
														boxShadow: 'inset 10px 0px 20px -10px rgb(0 0 0 / 25%)',
														zIndex: 8,
													}}
												></div>
											</>
										)}
										{/* Book */}
										{bookActive.pageDimensions.orientation === 'horizontal' && (
											<BookHorizontal book={book} visiblePages={visiblePages} />
										)}
										{bookActive.pageDimensions.orientation === 'vertical' && (
											<BookVertical bookPages={pages} visiblePages={visiblePages} />
										)}
										<Drawing />
										{/* Outher shadows */}
										{bookActive.pageDimensions.orientation === 'horizontal' && (
											<>
												<div
													className={classes.shadow}
													style={{
														transitionDelay: shadow ? '1.5s' : '0s',
														transitionDuration: shadow ? '1.5s' : '0s',
														opacity: shadow ? 1 : 0,
														marginLeft: -bookActive.pageDimensions.width,
														width: bookActive.pageDimensions.width,
														height: bookActive.pageDimensions.height,
														boxShadow: '-10px 0px 20px -10px rgb(0 0 0 / 50%)',
													}}
												></div>
												<div
													className={classes.shadow}
													style={{
														transitionDelay: shadow ? '1.5s' : '0s',
														transitionDuration: shadow ? '1.5s' : '0s',
														opacity: shadow ? 1 : 0,
														width: bookActive.pageDimensions.width,
														height: bookActive.pageDimensions.height,
														boxShadow: '10px 0px 20px -10px rgb(0 0 0 / 50%)',
													}}
												></div>
											</>
										)}
									</div>
								</Box>
							)}
						</div>
					</Box>
				</Grid>
				<Grid
					item
					sx={{
						position: 'absolute',
						right: bookActive?.bonuses ? 16 + 320 : theme.spacing(2),
						transition: 'right 0.5s',
						top: 'calc(50vh - 40px)',
						zIndex: 9,
					}}
				>
					<div
						onClick={() => nextPage(bookActive)}
						className={classes.nav}
						style={{
							opacity: visiblePages[0] === props.bookPages.length - 2 ? 0 : undefined,
						}}
						id="book-nav-next"
					>
						<ChevronRight style={{ fontSize: 80 }} />
					</div>
				</Grid>
			</Grid>
			<BookOptions hasUaAudio={hasUaAudio} />
			<AudioPlayer />
		</Box>
	);
};

export default connect((state: Types.RootState) => ({
	bookPages: state.bookPages,
	user: state.user,
	bookActive: state.bookActive,
}))(BookDetail);

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import React, { ReactNode } from 'react';
import useStyles from '../styles';
import T from '../T';

export interface ConfirmDialogProps {
	onClose: () => void;
	onSubmit: () => void;
	type: 'add' | 'remove' | 'approve' | 'update';
	title: string | ReactNode;
	description?: string | ReactNode;
}

const ConfirmDialog = (props: ConfirmDialogProps) => {
	const onClose = props.onClose;
	const onSubmit = props.onSubmit;
	const title = props.title;
	const description = props.description;
	const classes = useStyles();

	return (
		<Dialog open onClose={() => onClose()}>
			<DialogTitle>{title}</DialogTitle>
			{description && (
				<DialogContent>
					<DialogContentText>{description}</DialogContentText>
				</DialogContent>
			)}
			<DialogActions className={classes.dialogActions}>
				<Button onClick={() => onClose()} className="confirm-dialog-no">
					<T id="dialogs.confirm.cancel" />
				</Button>
				<Button
					onClick={() => {
						onClose();
						onSubmit();
					}}
					color="primary"
					autoFocus
					className="confirm-dialog-yes"
				>
					<T id={`dialogs.confirm.${props.type}`} />
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default ConfirmDialog;

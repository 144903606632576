import Types from '@taktik/common/types';
import { connect, useDispatch } from 'react-redux';
import TaktikIcon from '@taktik/components/TaktikIcon';
import AudioDialog from './AudioDialog';
import { useState } from 'react';
import reducers from '@taktik/common/reducers';
import { AxiosResponse } from 'axios';

interface AudioEditorProps {
	user: Types.UserSession;
	page: Types.BookPageDocument;
	bookActive: Types.BookActiveProps;
}

const AudioEditor = (props: AudioEditorProps) => {
	const dispatch = useDispatch();
	const bookActive = props.bookActive;
	const user = props.user;
	const page = props.page;
	const [audioDialog, setAudioDialog] = useState<Types.AudioDocument>();

	return bookActive ? (
		<>
			<div
				className="audios"
				style={{
					width: bookActive?.pageDimensions.width,
					height: bookActive?.pageDimensions.height,
					cursor: 'copy',
				}}
				onClick={event => {
					const offset = event.currentTarget.getBoundingClientRect();
					const x = ((event.clientX - offset.left) / bookActive.pageDimensions.width) * 100;
					const y = ((event.clientY - offset.top) / bookActive.pageDimensions.height) * 100;
					dispatch(
						reducers.interaktiv.bookPages.actions.update(
							'add/audio',
							{
								pageId: page._id,
								data: {
									value: {
										x,
										y,
									},
								},
							},
							user.token
						)
					);
				}}
			>
				{bookActive &&
					page.audio.map(audio => {
						const size = bookActive.pageDimensions.width * (4 / 100);
						return (
							<div
								key={`audio-${audio._id}`}
								style={{
									cursor: 'pointer',
									top: `${bookActive.pageDimensions.height * (audio.y / 100) - size / 2}px`,
									left: `${bookActive.pageDimensions.width * (audio.x / 100)}px`,
									width: `${size}px`,
									height: `${size}px`,
								}}
								className={`audio`}
								onClick={event => {
									event.preventDefault();
									event.stopPropagation();
									setAudioDialog(audio);
								}}
							>
								<TaktikIcon
									icon="note"
									size={bookActive.pageDimensions.width * (2.5 / 100)}
									style={{
										paddingTop: `${bookActive.pageDimensions.width * (0.6 / 100)}px`,
									}}
								/>
							</div>
						);
					})}
			</div>
			{audioDialog && (
				<AudioDialog audio={audioDialog} page={page} onClose={() => setAudioDialog(undefined)} />
			)}
		</>
	) : null;
};

export default connect((state: Types.RootState) => ({
	user: state.user,
	bookActive: state.bookActive,
}))(AudioEditor);

import Types from '@taktik/common/types';
import { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import TaktikIcon from '@taktik/components/TaktikIcon';
import reducers from '@taktik/common/reducers';

interface AudioProps {
	bookActive: Types.BookActiveProps;
	page: Types.BookPageDocument;
}

const Audio = (props: AudioProps) => {
	const dispatch = useDispatch();
	const page = props.page;
	const bookActive = props.bookActive;
	const uaAudio = bookActive?.uaAudio;
	const [audioFiles, setAudioFiles] = useState(page.audio.filter(a => (uaAudio ? true : !a.ua)));

	const playAudio = (audio: Types.AudioDocument) => {
		if (bookActive) {
			dispatch(
				reducers.interaktiv.bookActive.actions.set({
					...bookActive,
					audio,
				})
			);
		}
	};

	useEffect(() => {
		setAudioFiles(page.audio.filter(a => (uaAudio ? true : !a.ua)));
	}, [page, uaAudio]);

	return bookActive && audioFiles.length > 0 ? (
		<div className="audios">
			{audioFiles.map(audio => {
				const size = bookActive.pageDimensions.width * (4 / 100);

				return (
					<div
						key={`audio-${audio._id}`}
						style={{
							cursor: 'pointer',
							top: `${bookActive.pageDimensions.height * (audio.y / 100) - size / 2}px`,
							left: `${bookActive.pageDimensions.width * (audio.x / 100)}px`,
							width: `${size}px`,
							height: `${size}px`,
						}}
						className={`audio ${audio.ua && 'ua-audio'} ${
							bookActive.audio?._id === audio._id ? 'active' : ''
						}`}
						onClick={() => {
							playAudio(audio);
						}}
					>
						<span className="ua-icon">
							<TaktikIcon
								icon="note"
								size={bookActive.pageDimensions.width * (2.5 / 100)}
								style={{
									paddingTop: `${bookActive.pageDimensions.width * (0.6 / 100)}px`,
								}}
							/>
						</span>
					</div>
				);
			})}
		</div>
	) : null;
};

export default connect((state: Types.RootState) => ({
	bookActive: state.bookActive,
}))(Audio);
